import { MessageBundle } from '@amzn/arb-tools';

export const safeGetMessage = (
  bundle: MessageBundle,
  stringId: string,
  args?: Record<string, unknown>,
  defaultValue?: string,
): string => {
  if (!bundle || !stringId) {
    return '';
  }
  try {
    if (!args) {
      return bundle.getMessage(stringId);
    }
    return bundle.formatMessage(stringId, args);
  } catch (e) {
    if (defaultValue) {
      return defaultValue;
    }
    // Temporarily use console log until we have a better solution to track these errors
    console.log(`Error retrieving text for ${stringId}: ${e.message}`);
    return `[${stringId}]`;
  }
};

export { getLocalizationOptions } from './getLocalizationOptions';
