import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import OpenModalButton, {
  OpenModalButtonProps,
} from 'components/OpenModalButton';
import { MessageBundle } from '@xbcb/ui-types';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

const createRecordModalContent = (bundle: MessageBundle) => (
  <>
    <PlusOutlined /> {safeGetMessage(bundle, 'create')}
  </>
);

interface CreateButtonModalProps extends OpenModalButtonProps {
  modalContent?: React.ReactNode | JSX.Element;
}
// TODO Add support for Modals that have required properties such as CreateUsIorContinuousBondRequestModal.
// They would render but have an inconsistent state as the props that are required would be `undefined`
export const CreateButtonModal: React.FC<CreateButtonModalProps> = ({
  modalContent,
  ...props
}: CreateButtonModalProps) => {
  const [bundle] = useBundle('shared');
  return (
    <OpenModalButton key={`${props.modalKey}Button`} {...props}>
      {modalContent ? modalContent : createRecordModalContent(bundle)}
    </OpenModalButton>
  );
};

export default CreateButtonModal;
