import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import {
  DELIVERY_ORDER_AUTOMATION_FEATURE,
  deliveryOrderAutomationFeature,
} from '@xbcb/feature-flags';

export const isDeliveryOrderAutomationEnabled =
  DELIVERY_ORDER_AUTOMATION_FEATURE.isEnabled(deliveryOrderAutomationFeature, {
    stage: uiStageToBackendStage[getEnv().stage],
  });
