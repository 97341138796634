import { IdInput } from '@xbcb/shared-schema';
import { WorkOrderTaskType, WorkOrderType } from '@xbcb/work-order-types';
import {
  AnyObject,
  OperatorUserTeamRole,
  RecordType,
} from '@xbcb/shared-types';
import { filterWorkOrderTaskTypes } from 'libs/filterWorkOrderTaskTypes';
import type { AssignmentTeamOperatorGroup } from '@xbcb/api-gateway-client';
import { getRecordType } from '@xbcb/core';
import { WOT_TYPES_WITH_COMMODITY_GROUPS } from 'libs/constant';
import { isCommodityGroupViewEnabled } from 'libs/featureFlags';

const iorRecordTypesMap = [
  { key: 'usIors', type: RecordType.US_IOR },
  { key: 'deIors', type: RecordType.DE_IOR },
  { key: 'gbIors', type: RecordType.GB_IOR },
  { key: 'frIors', type: RecordType.FR_IOR },
  { key: 'nlIors', type: RecordType.NL_IOR },
];

export const transformAssignmentTeam = {
  toForm: ({
    existingRecord: newRecord,
  }: {
    existingRecord: AnyObject;
  }): AnyObject => {
    const { verticals, workOrderTypes, workOrderTaskTypes, operatorUsers } =
      newRecord;
    const groups: {
      operators: { id: string[] };
      primaryTeamLead?: { id: string };
      secondaryTeamLead?: { id: string };
      viewers?: { id: string[] };
    }[] = [];
    operatorUsers?.edges?.forEach((edge: AnyObject) => {
      const { id } = edge.node;
      edge.relationship?.groups?.forEach(
        ({
          roles,
          groupNumber,
        }: {
          roles: OperatorUserTeamRole[];
          groupNumber: number;
        }) => {
          while (groupNumber >= groups.length) {
            groups.push({
              operators: { id: [] },
              viewers: { id: [] },
            });
          }
          roles.forEach((role: OperatorUserTeamRole) => {
            if (role === OperatorUserTeamRole.OPERATOR) {
              groups[groupNumber].operators.id.push(id);
            } else if (role === OperatorUserTeamRole.PRIMARY_TEAM_LEAD) {
              groups[groupNumber].primaryTeamLead = { id };
            } else if (role === OperatorUserTeamRole.SECONDARY_TEAM_LEAD) {
              groups[groupNumber].secondaryTeamLead = { id };
            } else if (role === OperatorUserTeamRole.VIEWER) {
              groups[groupNumber].viewers?.id.push(id);
            }
          });
        },
      );
    });
    newRecord.groups = groups;
    newRecord.verticals = verticals?.map((vertical: AnyObject) => {
      vertical.forwarders = {
        id: vertical.forwarders?.map((forwarder: AnyObject) => forwarder.id),
      };
      vertical.shippers = {
        id: vertical.shippers?.map((shipper: AnyObject) => shipper.id),
      };
      vertical.iors?.forEach((record: AnyObject) => {
        const recordType = getRecordType(record.id);
        iorRecordTypesMap.forEach(({ key, type }) => {
          if (recordType === type) {
            if (!vertical[key]) {
              vertical[key] = { id: [] };
            }
            vertical[key].id.push(record.id);
          }
        });
      });
      if (isCommodityGroupViewEnabled) {
        vertical.commodityGroup = {
          id: vertical.commodityGroup?.map(
            (commodityGroup: AnyObject) => commodityGroup.id,
          ),
        };
      }
      return vertical;
    });
    const workOrderSpecialties: AnyObject = {};
    workOrderTypes.forEach((workOrderType: WorkOrderType) => {
      const field = workOrderType;
      const values = filterWorkOrderTaskTypes(
        workOrderType,
        workOrderTaskTypes,
      );
      workOrderSpecialties[field] = values;
    });
    newRecord.workOrderSpecialties = workOrderSpecialties;
    return newRecord;
  },
  toSchema: ({
    input,
    existingRecord,
    isCreate,
  }: {
    input: any;
    existingRecord?: any;
    isCreate?: boolean;
  }): AnyObject => {
    const values = JSON.parse(JSON.stringify(input));
    const { accountId, customsBrokerId, workOrderSpecialties, groups } = values;
    if (isCreate) {
      if (accountId) {
        values.operator = { id: accountId };
      }
      if (customsBrokerId) {
        values.customsBroker = { id: customsBrokerId };
      }
    } else {
      delete values.operator;
    }
    delete values.accountId;
    delete values.customsBrokerId;

    values.members = [];
    groups?.forEach(
      (
        group: {
          operators: { id: string[] };
          primaryTeamLead: { id: string };
          secondaryTeamLead?: { id: string };
          viewers?: { id: string[] };
        },
        groupNumber: number,
      ) => {
        group.operators.id?.map((id: string) =>
          addToMembers(
            values.members,
            id,
            OperatorUserTeamRole.OPERATOR,
            groupNumber,
          ),
        );
        addToMembers(
          values.members,
          group.primaryTeamLead.id,
          OperatorUserTeamRole.PRIMARY_TEAM_LEAD,
          groupNumber,
        );
        if (group.secondaryTeamLead) {
          addToMembers(
            values.members,
            group.secondaryTeamLead.id,
            OperatorUserTeamRole.SECONDARY_TEAM_LEAD,
            groupNumber,
          );
        }
        if (group.viewers) {
          group.viewers.id?.map((id: string) =>
            addToMembers(
              values.members,
              id,
              OperatorUserTeamRole.VIEWER,
              groupNumber,
            ),
          );
        }
      },
    );

    delete values.groups;
    delete values.operatorUsers;
    const updatedWorkOrderTaskTypes: WorkOrderTaskType[] = [];
    const updatedWorkOrderTypes = Object.keys(workOrderSpecialties).reduce(
      (acc: string[], key) => {
        if (workOrderSpecialties[key]) {
          updatedWorkOrderTaskTypes.push(...workOrderSpecialties[key]);
          acc.push(key);
        }
        return acc;
      },
      [],
    );
    delete values.workOrderSpecialties;
    values.workOrderTypes = updatedWorkOrderTypes;
    values.workOrderTaskTypes = updatedWorkOrderTaskTypes;
    values.verticals =
      values.verticals?.map((vertical: any) => {
        const forwarders = vertical?.forwarders?.id?.map((ID: IdInput) => ({
          id: ID,
        }));

        const shippers = vertical?.shippers?.id?.map((ID: IdInput) => ({
          id: ID,
        }));

        // Only certain WOT types can carry commodity groups
        const canHaveCommodityGroup = WOT_TYPES_WITH_COMMODITY_GROUPS.some(
          (wotType) => updatedWorkOrderTaskTypes.includes(wotType),
        );

        let commodityGroup;
        if (isCommodityGroupViewEnabled && canHaveCommodityGroup) {
          commodityGroup = vertical?.commodityGroup?.id?.map((ID: IdInput) => ({
            id: ID,
          }));
        } else {
          commodityGroup = null;
        }

        // iors is flattened object with ids
        const iors = [...iorRecordTypesMap.map(({ key }) => key)].reduce(
          (acc: AnyObject[], key) => {
            const iorIds = vertical[key]?.id.map((ID: IdInput) => ({
              id: ID,
            }));
            if (iorIds) {
              acc.push(...iorIds);
            }
            if (vertical[key]) {
              delete vertical[key];
            }
            return acc;
          },
          [],
        );
        return { ...vertical, forwarders, shippers, iors, commodityGroup };
      }) || [];
    return values;
  },
};

const addToMembers = (
  members: {
    userId: string;
    roles: OperatorUserTeamRole[];
    groups?: AssignmentTeamOperatorGroup[];
  }[],
  id: string,
  role: OperatorUserTeamRole,
  groupNumber: number,
) => {
  const existingMember = members.find(
    (operator: { userId: string }) => operator.userId === id,
  );
  if (existingMember) {
    if (!existingMember.roles.includes(role)) existingMember.roles.push(role);
    const existingGroup = existingMember.groups?.find(
      (group) => group.groupNumber === groupNumber,
    );
    if (existingGroup) {
      existingGroup.roles.push(role);
    } else {
      existingMember.groups
        ? existingMember.groups.push({ roles: [role], groupNumber })
        : (existingMember.groups = [{ roles: [role], groupNumber }]);
    }
  } else {
    const newMember = {
      userId: id,
      roles: [role],
      groups: [{ roles: [role], groupNumber }],
    };
    members?.push(newMember);
  }
};
