export enum KebabMenuItemKey {
  MEMOS = 'memos',
  INQUIRE_WORK_ORDER = 'inquireWorkOrder',
  UPLOAD_INVOICE = 'uploadInvoice',
  ABI_DATA = 'abiData',
  CHANGE_MOT = 'changeMot',
  ASSIGN = 'assign',
  MODIFY_PREFERRED_DUTY_COLLECT_PARTY_TYPE = 'modifyPreferredDutyCollectPartyType',
  CREATE_US_POST_SUMMARY_CORRECTION = 'createUsPostSummaryCorrection',
  GENERATE_ENTRY_NUMBER = 'generateEntryNumber',
  SEND_DOCUMENTS_TO_CBP = 'sendDocumentsToCbp',
  MODIFY_AMS_INDICATOR = 'modifyAmsIndicator',
  SEND_ABI_MESSAGE = 'sendAbiMessage',
  BLOCK_TASK = 'blockTask',
  UNBLOCK_TASK = 'unblockTask',
  GENERATE_EXAM_DELIVERY_ORDER = 'generateExamDeliveryOrder',
}
