import { omit } from 'lodash';
import type { UsIor as ApigUsIor } from '@xbcb/api-gateway-client';
import { getIsIorActivated } from '@xbcb/party-utils';
import { AccountType, RecordType, AnyObject } from '@xbcb/shared-types';
import { UiStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import { transformParty } from 'libs/formTransforms';
import validateUsIor from 'libs/validateUsIor';
import { UsIor } from '../../loadableComponents';
import { AppRecordKebabMenuItem, AppRecordRoute } from '../../types';
import {
  appRecordAbiDataMenuItem,
  appRecordCreateUsIorContinuousBondRequestMenuItem,
  appRecordMemoMenuItem,
  createQueryUsIorKebabMenuItem,
  createUsIorCreate5106KebabMenuItem,
  createUsIorUpdate5106KebabMenuItem,
  createUsIorRequestCbpAssignedNumberKebabMenuItem,
} from '../utils';
import UsIorAdditionalTitleContent from 'components/UsIorAdditionalTitleContent';
import { setModal } from 'actions';
import { ModalKey } from 'types';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { usIorFields, usIorFragments } from 'libs/sharedQueries';

const transformUpdateInput = ({
  input,
  existingRecord,
}: {
  input: AnyObject;
  existingRecord: any;
}): AnyObject => {
  // handle the case when the value is cleared on the UI.
  // i.e., it is defined in existingRecord and undefined in input
  // Make those values null
  // Note 1: We need to make them null as undefined values are not added to the update payload and hence not updated.
  // Note 2: doesn't work for nested fields
  const keysToIgnore = [
    '__typename',
    'id',
    'version',
    'created',
    'updated',
    'deleted',
    'tags',
    'memos',
    'cbpPhysicalAddressType', // can this be removed from this list ?
  ];
  // Consider making this a lib if there is another page that needs this
  Object.entries(existingRecord).forEach(([key, value]) => {
    if (value && input[key] === undefined && !keysToIgnore.includes(key)) {
      input[key] = null; // making 'undefined' key to 'null'
    }
  });

  // special handling for iorNumber
  // If there's no `iorNumber.value` we should delete `iorNumber` entirely as if it is
  // provided the `iorNumber.value` is required
  if (!input.iorNumber?.value) input.iorNumber = null;

  // explicitly remove un-needed values
  const keysToRemove = [
    'documentSignRequests',
    'shippers',
    'paymentTermsUpdated',
  ];
  return transformParty.toSchema({ input: omit(input, keysToRemove) });
};

const { stage } = getEnv();

const usIorRoute: AppRecordRoute = {
  Page: UsIor,
  recordType: RecordType.US_IOR,
  fields: usIorFields,
  fragments: usIorFragments,
  transformUpdateRecordInput: transformUpdateInput,
  transformRecordToInput: transformParty.toForm,
  validateUpdate: validateUsIor,
  kebabMenuItems: [
    appRecordAbiDataMenuItem,
    appRecordMemoMenuItem,
    createQueryUsIorKebabMenuItem(),
    createUsIorCreate5106KebabMenuItem(),
    createUsIorUpdate5106KebabMenuItem(),
    createUsIorRequestCbpAssignedNumberKebabMenuItem(),
    appRecordCreateUsIorContinuousBondRequestMenuItem,
    {
      text: 'Link Forwarder',
      show: ({ currentUser, existingRecord }) =>
        currentUser?.accountType === AccountType.OPERATOR &&
        checkAccess(currentUser, RecordType.US_IOR, UserPermissions.UPDATE) &&
        getIsIorActivated({
          ior: existingRecord,
          customsBrokerId: currentUser?.customsBroker?.id,
        }),
      action: ({ dispatch }) => {
        dispatch(
          setModal({
            key: ModalKey.LINK_FORWARDER,
            props: { visible: true },
          }),
        );
      },
    } as AppRecordKebabMenuItem,
  ],
  // Forwarder users should not be able to edit the IOR, i.e. let's hide the
  // edit button when they are logged in
  hideDefaultEditButton: ({ accountType, record, customsBrokerId }) =>
    accountType === AccountType.FORWARDER ||
    // Operator users for now can edit the IOR after activation. This is needed
    // because in the FBA use case, there is often missing data and the IOR is
    // auto-activated. In the future we need to look into the IORs created via
    // the FBA use case to include all the info we need so that we can remove
    // this check and not allow any user account type to edit the IOR after
    // it's activated
    (accountType !== AccountType.OPERATOR &&
      getIsIorActivated({ ior: record as ApigUsIor, customsBrokerId })),
  // Forwarder users should not be able to delete the IOR, i.e. let's hide the
  // delete kebab menu item when they are logged in
  hideDeleteKebabMenuItem: ({ accountType }) =>
    stage !== UiStage.PROD && accountType === AccountType.FORWARDER, // The operator should use cancel button now
  showCancelUncancelKebabMenuItem: ({ accountType }) =>
    stage !== UiStage.PROD && accountType !== AccountType.FORWARDER,
  AdditionalTitleContent: ({ record }) => (
    <UsIorAdditionalTitleContent record={record} />
  ),
};

export default usIorRoute;
