import { gql } from '@apollo/client';

export const SEARCH_COMMODITY_GROUPS = gql`
  query SearchCommodityGroup($input: SearchCommodityGroupInput!) {
    searchCommodityGroups(input: $input) {
      results {
        id
        name
        description
        htsChapters
        pgas
      }
    }
  }
`;
