import React from 'react';
import { FormItem, Select } from '@xbcb/form-item-components';
import { useTheme } from 'styled-components';
import { VneRegulatoryCategory } from '__generated__/graphql';
import { RemarksTypeCodeProps } from '../types';
import { industryCodeOptions } from './industryCodes';

export const IndustryCode: React.FC<RemarksTypeCodeProps> = ({
  localNamePath,
  readOnly,
  disabled,
  regulatoryCategory,
  onChange,
  required,
}) => {
  const theme = useTheme();

  const shouldShow =
    regulatoryCategory ===
    VneRegulatoryCategory.HEAVY_DUTY_HIGHWAY_AND_NON_ROAD;

  return shouldShow ? (
    <FormItem
      $itemSize={theme.size.medium}
      label="Industry Code"
      name={[...localNamePath, 'vneIndustryCode']}
      $readOnly={readOnly}
      rules={[{ required }]}
    >
      <Select
        aria-label={'Industry Code Select'}
        disabled={disabled}
        options={industryCodeOptions}
        onChange={onChange}
      />
    </FormItem>
  ) : null;
};
