import { client as apolloClient } from '@xbcb/apollo-client';
import {
  CommodityGroupSearchCriteriaInput,
  SearchCommodityGroupQuery,
} from '__generated__/graphql';
import { SEARCH_COMMODITY_GROUPS } from './searchCommodityGroups.query';

export const fetchCommodityGroups = async (
  searchCriteria: CommodityGroupSearchCriteriaInput,
): Promise<SearchCommodityGroupQuery['searchCommodityGroups']['results']> => {
  const response = await apolloClient.query<SearchCommodityGroupQuery>({
    query: SEARCH_COMMODITY_GROUPS,
    variables: {
      input: {
        searchCriteria,
      },
    },
  });

  return response.data.searchCommodityGroups.results;
};
