import { Stage } from '@xbcb/shared-types';

export const isfTemplateId = {
  [Stage.ALPHA]: 'document_af7579ba-10de-48e7-aa32-fd1953cd5958',
  [Stage.BRAVO]: 'document_af7579ba-10de-48e7-aa32-fd1953cd5958',
  [Stage.BETA]: 'document_0085e029-2096-4a98-b71c-b0bc0597d587',
  [Stage.GAMMA]: 'document_75e6a1f1-4ea5-46d2-820b-883469a84f83',
  [Stage.PROD]: 'document_2f99a80d-6e8f-43ac-852f-4e9f81296cfe',
};

export const bulkMilestoneUploadTemplateId = {
  [Stage.ALPHA]: 'document_a8913874-d271-4690-a844-34bbcc1fc8a0',
  [Stage.BRAVO]: 'document_57cb96d0-449b-433f-ba51-67d4869cfbe1',
  [Stage.BETA]: 'document_57cb96d0-449b-433f-ba51-67d4869cfbe1',
  [Stage.GAMMA]: 'document_ff89b53e-0310-422f-8fa3-23f4f1c85c1f',
  [Stage.PROD]: 'document_9f39b20c-6fa6-4b9c-bd16-0c898d2c6431',
};
