import { AnyObject } from '@xbcb/shared-types';
import { AdditionalFormError } from '@xbcb/ui-types';
import {
  AppRecordValidateCreateProps,
  AppRecordValidateUpdateProps,
} from 'routes';
import { fetchCommodityGroups } from 'libs/validateCommodityGroup/fetchCommodityGroups';
import { uniqBy } from 'lodash';
import { DateSearchOperator, TextSearchOperator } from '__generated__/graphql';

export const validateCommodityGroupCreate = (
  props: AppRecordValidateCreateProps,
): Promise<{
  additionalErrors: AdditionalFormError[];
}> => {
  return validateCommodityGroup({
    payload: props.input,
  });
};

export const validateCommodityGroupUpdate = (
  props: AppRecordValidateUpdateProps,
): Promise<{
  additionalErrors: AdditionalFormError[];
}> => {
  return validateCommodityGroup({
    payload: props.input,
    commodityGroupId: props.existingRecord?.id,
  });
};

interface DuplicateGroup {
  groupName: string;
  overlappingHtsChapters: string[];
  overlappingPgas: string[];
}

type ValidateCommodityGroupProps = {
  payload: AnyObject;
  commodityGroupId?: string;
};

export const validateCommodityGroup = async ({
  payload,
  commodityGroupId,
}: ValidateCommodityGroupProps): Promise<{
  additionalErrors: AdditionalFormError[];
}> => {
  const additionalErrors: AdditionalFormError[] = [];
  const { name, description, htsChapters, pgas } = payload;

  // Basic validations
  if (!name?.trim()) {
    additionalErrors.push({
      title: 'Name Required',
      messages: ['A name is required for the Commodity Group'],
    });
  }

  if (!description?.trim()) {
    additionalErrors.push({
      title: 'Description Required',
      messages: ['A description is required for the Commodity Group'],
    });
  }

  if (!htsChapters?.length && !pgas?.length) {
    additionalErrors.push({
      title: 'HTS Chapter or PGA Required',
      messages: ['At least one HTS Chapter or PGA must be selected'],
    });
    return { additionalErrors };
  }

  try {
    const htsCommodityGroups = htsChapters?.length
      ? await fetchCommodityGroups({
          htsChapters: {
            values: htsChapters,
            operator: TextSearchOperator.ONE_OF,
          },
          deletedTime: {
            operator: DateSearchOperator.DOES_NOT_EXIST,
          },
        })
      : [];

    // Fetch records with matching PGAs
    const pgaCommodityGroups = pgas?.length
      ? await fetchCommodityGroups({
          pgas: {
            values: pgas,
            operator: TextSearchOperator.ONE_OF,
          },
          deletedTime: {
            operator: DateSearchOperator.DOES_NOT_EXIST,
          },
        })
      : [];

    // Combine and deduplicate results
    const existingCommodityGroups = uniqBy(
      [...htsCommodityGroups, ...pgaCommodityGroups],
      'id',
    );

    const duplicatesMap = existingCommodityGroups.reduce(
      (acc: DuplicateGroup[], group) => {
        // Skip current group when updating
        if (group.id === commodityGroupId) return acc;

        // Find overlapping HTS Chapters
        const overlappingHtsChapters =
          group.htsChapters?.filter((chapter: string) =>
            htsChapters?.includes(chapter),
          ) || [];

        // Find overlapping PGAs
        const overlappingPgas =
          group.pgas?.filter((pga: string) => pgas?.includes(pga)) || [];

        if (overlappingHtsChapters.length || overlappingPgas.length) {
          acc.push({
            groupName: group.name,
            overlappingHtsChapters,
            overlappingPgas,
          });
        }

        return acc;
      },
      [],
    );

    if (duplicatesMap.length) {
      const messages = ['The setting is also used in below CG(s):'];

      // Group duplicates by type
      const htsOverlaps = duplicatesMap
        .filter((d: DuplicateGroup) => d.overlappingHtsChapters.length)
        .map(
          (d: DuplicateGroup) =>
            `${d.overlappingHtsChapters.join(', ')} : ${d.groupName}`,
        );

      const pgaOverlaps = duplicatesMap
        .filter((d: DuplicateGroup) => d.overlappingPgas.length)
        .map(
          (d: DuplicateGroup) =>
            `${d.overlappingPgas.join(', ')} : ${d.groupName}`,
        );

      if (htsOverlaps.length) {
        messages.push('Duplicate HTS Chapters:', ...htsOverlaps);
      }

      if (pgaOverlaps.length) {
        messages.push('Duplicate PGAs:', ...pgaOverlaps);
      }

      additionalErrors.push({
        title: 'Duplicate Values Found',
        messages,
      });
    }
  } catch (error) {
    additionalErrors.push({
      title: 'Validation Error',
      messages: ['An error occurred while validating the commodity group.'],
    });
  }

  return { additionalErrors };
};
