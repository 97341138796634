import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { createOneMutation } from '@xbcb/shared-queries';
import { RecordType, Tag } from '@xbcb/shared-types';
import { Button, Form } from 'antd';
import { UploadDocBox, DocumentFields } from '@xbcb/document-components';
import { HiddenField } from '@xbcb/form-item-components';
import handleRecordCreate from 'libs/handleRecordCreate';
import { useCurrentUser, useCustomsBrokerId } from 'libs/hooks';
import { useHistory } from 'react-router-dom';
import {
  StyledDiv,
  StyledFileExcelOutlined,
  StyledForm,
  StyledModal,
  StyledSpan,
} from './styles';
import { ModalKey } from 'types';
import { useModal } from '@xbcb/ui-utils';

export type CreateBulkUploadRequestModalProps = {
  modalKey: ModalKey;
  recordName: RecordType;
  tagKey: string;
  title: string;
  transformCreateRecordInput: any;
};

const CreateBulkUploadRequestModal = ({
  modalKey,
  recordName,
  tagKey,
  transformCreateRecordInput,
  title,
}: CreateBulkUploadRequestModalProps): JSX.Element => {
  const { closeModal, visible } = useModal(modalKey);
  const user = useCurrentUser();
  const [form] = Form.useForm();
  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [documentList, setDocumentList] = useState<DocumentFields[]>([]);
  const customsBrokerId = useCustomsBrokerId();

  const [createRecord, { loading }] = useMutation(
    createOneMutation({
      recordName,
      fields: `
      id
      status
    `,
    }),
  );
  const handlePostUpload = (document: DocumentFields) => {
    setDocumentList([document]);
    setIsUploading(false);
  };
  const handleClose = () => {
    setDocumentList([]);
    closeModal();
  };
  const operatorId = user.operator?.id;
  const { id: documentId, fileName: documentName } = documentList[0]
    ? documentList[0]
    : { id: undefined, fileName: undefined };
  const isCreateButtonDisabled = isUploading || !documentList.length;
  const tags: Tag[] = [
    {
      key: tagKey,
      value: operatorId,
    },
    {
      key: 'customsBrokerId',
      value: customsBrokerId,
    },
  ];
  return (
    <StyledForm form={form}>
      <StyledModal
        destroyOnClose
        title={title}
        maskClosable={loading}
        onCancel={handleClose}
        open={visible}
        width={700}
        footer={[
          <Button key="back" onClick={handleClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            disabled={isCreateButtonDisabled}
            type="primary"
            onClick={async () =>
              handleRecordCreate({
                form,
                history,
                recordType: recordName,
                transformCreateRecordInput,
                createRecord,
                clean: false,
                closeModal,
                user,
              })
            }
          >
            Create
          </Button>,
        ]}
      >
        <div>
          {documentList[0] ? (
            <>
              <h4>Uploaded document: </h4>
              <StyledDiv>
                <div>
                  <StyledFileExcelOutlined />
                  <StyledSpan>{documentName}</StyledSpan>
                </div>
              </StyledDiv>
            </>
          ) : (
            <>
              <h4>Upload document here: </h4>
              <UploadDocBox
                key="upload"
                acceptedFileTypes={'.xlsx'}
                uploadLoadText={'Click or drag to upload document (.xlsx)'}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                documentList={documentList}
                setDocumentList={setDocumentList}
                searchQueryTags={tags}
                tagsForNewDocuments={tags}
                operatorId={operatorId}
                postUpload={() => handlePostUpload(documentList[0])}
              />
            </>
          )}
        </div>
        <HiddenField
          localNamePath={['documentId']}
          fullNamePath={['documentId']}
          value={documentId}
          form={form}
        />
        <HiddenField
          localNamePath={['operatorId']}
          fullNamePath={['operatorId']}
          value={operatorId}
          form={form}
        />
      </StyledModal>
    </StyledForm>
  );
};

export default CreateBulkUploadRequestModal;
