import { AnyObject } from '@xbcb/shared-types';
import { transformEuCustomsEntry } from '../euCustomsEntry';

export const transformCustomsDeclaration = {
  toSchema: ({
    input,
    existingRecord,
  }: {
    input: AnyObject;
    existingRecord: AnyObject;
  }): AnyObject => {
    const cleanedOutput = transformEuCustomsEntry.toSchema({
      input,
      existingRecord,
    });

    cleanedOutput.goodsShipment?.forEach((goodShipment: any) => {
      goodShipment.importer = goodShipment.importer?.map((importer: any) => ({
        id: importer.id,
      }));
    });

    return cleanedOutput;
  },
};
