import { Account } from './environment';

export const isInternalAccount = (awsAccountId: string) => {
  return (
    Object.entries(Account).find(
      ([accountName, accountId]) => accountId === awsAccountId,
    ) !== undefined
  );
};

export const isClientAuthorityAccount = (awsAccountId: string) => {
  return (
    awsAccountId === Account['xbcb-client-authority-dev'] ||
    awsAccountId === Account['xbcb-client-authority-prod']
  );
};

export const isImportSignAccount = (awsAccountId: string) =>
  awsAccountId === Account['xbcb-importsign-ui-dev'] ||
  awsAccountId === Account['xbcb-importsign-ui-prod'];

export const isNewAppUiAccount = (awsAccountId: string) =>
  awsAccountId === Account['xbcb-new-app-ui-dev'] ||
  awsAccountId === Account['xbcb-new-app-ui-prod'];

export const isExternalGatewayAccount = (awsAccountId: string) => {
  return (
    awsAccountId === Account['xbcb-external-gateway-alpha'] ||
    awsAccountId === Account['xbcb-external-gateway-beta'] ||
    awsAccountId === Account['xbcb-external-gateway-gamma'] ||
    awsAccountId === Account['xbcb-external-gateway-prod']
  );
};
