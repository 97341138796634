import { AdditionalHtsType } from '@xbcb/trade-data-types';
import { HtsObject } from 'types';

export const section232SteelInfoEffectiveDate = new Date('2024-11-21');

export const isSection232SteelMeltAndPourApplicableForAnyTariff: (
  htsObjects: (HtsObject | undefined)[],
  estimatedImportDate: Date | undefined,
) => boolean = (htsObjects, estimatedImportDate) => {
  return htsObjects.some((tariff) =>
    Boolean(
      containsAdditionalHtsWithSection232SteelType(tariff) &&
        estimatedImportDateIsOnOrAfterEffectiveDate(estimatedImportDate),
    ),
  );
};

const containsAdditionalHtsWithSection232SteelType: (
  hts?: HtsObject,
) => boolean = (hts) => {
  return Boolean(
    hts?.additional?.some(
      (additionalHts) => section232SteelTariffType === additionalHts?.type,
    ),
  );
};

const estimatedImportDateIsOnOrAfterEffectiveDate: (
  estimatedImportDate: Date | undefined,
) => boolean = (estimatedImportDate) => {
  return Boolean(
    estimatedImportDate &&
      estimatedImportDate >= section232SteelInfoEffectiveDate,
  );
};

const section232SteelTariffType = AdditionalHtsType.SECTION_232_STEEL;
