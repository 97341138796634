import { LocalizationContextBuilder } from '@amzn/arb-tools';
import type { Stage } from '@xbcb/shared-types';
import { translationCdnUrls } from './constants';

const DEFAULT_LOCALE = 'en-US';

export const localizationContextBuilder =
  new LocalizationContextBuilder().withDefaultLocale('en-US');

export interface LocalizationProps {
  stage: Stage;
  locale?: string;
}

export const getLocalizationOptions = ({
  stage,
  locale = DEFAULT_LOCALE,
}: LocalizationProps) => {
  const translationCdnUrl = translationCdnUrls[stage];

  return {
    defaultLocalizationContext: localizationContextBuilder
      .withLocale(locale)
      .withDefaultLocale(DEFAULT_LOCALE)
      .build(),
    resolveBundleUrl: (url?: string | undefined) =>
      url ? `${translationCdnUrl}/translations/${url}` : undefined,
  };
};
