import { gql } from '@apollo/client';

export const submitEntryDis = gql`
  mutation SubmitUsConsumptionEntryDis(
    $idempotencyKey: String!
    $input: SubmitUsConsumptionEntryDisInput!
    $recordId: ID!
  ) {
    submitUsConsumptionEntryDis(
      idempotencyKey: $idempotencyKey
      input: $input
      usConsumptionEntryId: $recordId
    ) {
      transaction {
        ... on SubmitUsConsumptionEntryDisTransaction {
          id
          version
        }
      }
    }
  }
`;

export const submitType86EntryDis = gql`
  mutation SubmitUsType86EntryDis(
    $idempotencyKey: String!
    $input: SubmitUsType86EntryDisInput!
    $recordId: ID!
  ) {
    submitUsType86EntryDis(
      idempotencyKey: $idempotencyKey
      input: $input
      usType86EntryId: $recordId
    ) {
      transaction {
        ... on SubmitUsType86EntryDisTransaction {
          id
          version
        }
      }
    }
  }
`;

export const createWorkOrderConfirmation = gql`
  mutation CreateWorkOrderConfirmation(
    $idempotencyKey: String!
    $input: CreateWorkOrderConfirmationInput!
  ) {
    createWorkOrderConfirmation(
      idempotencyKey: $idempotencyKey
      input: $input
    ) {
      record {
        id
      }
    }
  }
`;

export const getWorkOrderQuery = gql`
  query getWorkOrderQuery($id: ID!, $version: Int) {
    workOrder(id: $id, version: $version) {
      id
      version
      status
      milestones {
        id
        name
        version
        occurenceTime
      }
    }
  }
`;

export const sendWorkOrderDocumentsTo3p = gql`
  mutation SendWorkOrderDocumentsTo3p(
    $input: SendWorkOrderDocumentsTo3pInput!
    $workOrderId: ID!
    $idempotencyKey: String!
  ) {
    sendWorkOrderDocumentsTo3p(
      input: $input
      workOrderId: $workOrderId
      idempotencyKey: $idempotencyKey
    ) {
      transaction {
        id
      }
    }
  }
`;
