import { VneFieldValidator, VneValidationArgs } from '../types';
import { VneRegulatoryCategory } from '__generated__/graphql';
import { AppRecordValidateResult } from '@xbcb/ui-types';

export class HeavyDutyVneValidator implements VneFieldValidator {
  isApplicable = ({ pga }: VneValidationArgs) =>
    pga.regulatoryCategory ===
    VneRegulatoryCategory.HEAVY_DUTY_HIGHWAY_AND_NON_ROAD;

  validate(_: VneValidationArgs): AppRecordValidateResult {
    return {
      validateFields: [['vneIndustryCode']],
    };
  }
}
