import cleanDeep from 'clean-deep';
import _, { pick, last, lowerCase, startCase } from 'lodash';
import {
  ShipmentLeg,
  ShipmentLegArrival,
  ShipmentBadge,
  ShipmentBuyer,
  ShipmentSeller,
  ShipmentShipTo,
  ShipmentComplianceDetail,
  Product,
  PortCodeInput,
  ExternalComplianceReference,
  UsIor,
  GbIor,
  DeIor,
  NlIor,
  FrIor,
  UsConsignee,
  GbConsignee,
  DeConsignee,
  NlConsignee,
  FrConsignee,
  UsConsumptionEntryBadge,
  UsConsumptionEntryIorInput,
  GbCustomsEntryIorInput,
  DeCustomsEntryIorInput,
  NlCustomsEntryIorInput,
  FrCustomsEntryIorInput,
  UsConsumptionEntryConsigneeInput,
  GbCustomsEntryConsigneeInput,
  DeCustomsEntryConsigneeInput,
  NlCustomsEntryConsigneeInput,
  FrCustomsEntryConsigneeInput,
  UsConsumptionEntryBuyerInput,
  UsConsumptionEntryCommercialInvoiceInput,
  GbCustomsEntryCommercialInvoiceInput,
  DeCustomsEntryCommercialInvoiceInput,
  NlCustomsEntryCommercialInvoiceInput,
  FrCustomsEntryCommercialInvoiceInput,
  UsConsumptionEntryDepartureInput,
  CustomsEntryDepartureInput,
  UsConsumptionEntryArrivalInput,
  CustomsEntryArrivalInput,
  UsConsumptionEntryConveyanceInput,
  CustomsEntryConveyanceInput,
  UsConsumptionEntryProductInput,
  GbCustomsEntryProductInput,
  DeCustomsEntryProductInput,
  NlCustomsEntryProductInput,
  FrCustomsEntryProductInput,
  UsConsumptionEntryLineInput,
  GbCustomsEntryLineInput,
  DeCustomsEntryLineInput,
  NlCustomsEntryLineInput,
  FrCustomsEntryLineInput,
  UsConsumptionEntryTariffInput,
  GbCustomsEntryTariffInput,
  DeCustomsEntryTariffInput,
  NlCustomsEntryTariffInput,
  FrCustomsEntryTariffInput,
  CreateUsConsumptionEntryInput,
  CreateGbCustomsEntryInput,
  CreateDeCustomsEntryInput,
  CreateNlCustomsEntryInput,
  CreateFrCustomsEntryInput,
  UsConsumptionEntry,
  GbCustomsEntry,
  DeCustomsEntry,
  NlCustomsEntry,
  FrCustomsEntry,
  ProductUsEntryLine,
  ProductGbEntryLine,
  ProductDeEntryLine,
  ProductNlEntryLine,
  ProductFrEntryLine,
  ShipmentCommercialInvoice,
} from '@xbcb/api-gateway-client';
import log from '@xbcb/log';
import { ModeOfTransport, RecordType } from '@xbcb/shared-types';
import {
  getPortOfLadingFromPortCode,
  getPortOfUnladingFromPortCode,
} from './getPort';
import { ComplianceRefToProductMap, createShipmentTags } from './shared';
import { getComplianceRefKey, roundNumber } from '../utils';

type TransformShipmentLegToEntryInputProps = {
  badges?: ShipmentBadge[];
  shipmentLeg: ShipmentLeg;
  clientIdentifier?: string;
  wogId: string;
  shipmentId: string;
  operatorId: string;
  buyer?: ShipmentBuyer;
  sellers?: ShipmentSeller[];
  shipTo?: ShipmentShipTo;
  complianceRefToProductMap: ComplianceRefToProductMap;
  // We use the existingWorkOrder to determine whether the consignee is
  // `sameAsIor`. This is optional, as when we only need it on update use cases
  existingWorkOrder?:
    | UsConsumptionEntry
    | GbCustomsEntry
    | DeCustomsEntry
    | NlCustomsEntry
    | FrCustomsEntry;
};

type SupportedEntryCommercialInvoiceInput =
  | UsConsumptionEntryCommercialInvoiceInput
  | GbCustomsEntryCommercialInvoiceInput
  | DeCustomsEntryCommercialInvoiceInput
  | NlCustomsEntryCommercialInvoiceInput
  | FrCustomsEntryCommercialInvoiceInput;

const PRECISION = 2;

export const transformShipmentLegToEntryInput = ({
  badges,
  shipmentLeg,
  clientIdentifier,
  wogId,
  shipmentId,
  operatorId,
  buyer,
  sellers,
  shipTo,
  complianceRefToProductMap,
  existingWorkOrder,
}: TransformShipmentLegToEntryInputProps) => {
  const {
    modeOfTransport,
    arrival,
    // TODO field is deprecated, not needed in this lib anymore since they are
    // stored on the WOG now
    poNumbers,
    containers,
    masterBills,
    ior,
    consignee,
    departure,
    conveyance,
    commercialInvoices,
    loadType,
  } = shipmentLeg;
  const arrivalCountry = arrival?.country;
  const entryType =
    arrivalCountry === 'US'
      ? 'UsConsumptionEntry'
      : `${startCase(arrivalCountry)}CustomsEntry`;
  let input = {
    tags: createShipmentTags({ shipmentId, clientIdentifier }),
    group: { id: wogId },
    operator: { id: operatorId },
    conveyance: { modeOfTransport },
    broker: arrival && getCustomsBroker(arrival),
    poNumbers,
    containers,
    masterBills,
    loadType,
    ...(arrivalCountry === 'US' && {
      badges: (badges || []) as UsConsumptionEntryBadge[],
    }),
  } as
    | CreateUsConsumptionEntryInput
    | CreateGbCustomsEntryInput
    | CreateDeCustomsEntryInput
    | CreateNlCustomsEntryInput
    | CreateFrCustomsEntryInput;
  log.info(`Created ${entryType}Input for Shipment [${shipmentId}]`, {
    key: `Create${entryType}Input`,
    id: shipmentId,
  });

  // When shipmentLeg has only one house bill or one master bill, map total containers' quantity to that only house bill.
  if (containers && masterBills) {
    if (masterBills.length === 1) {
      const firstMasterBill = masterBills[0];
      const houseBills = firstMasterBill.houseBills;
      if (!houseBills || houseBills.length === 1) {
        const number = houseBills ? houseBills[0].number : undefined;
        if (arrivalCountry === 'US') {
          let totalQuantity = 0;
          for (let i = 0; i < containers.length; i++) {
            const currentQuantity = containers[i].quantity;
            totalQuantity = currentQuantity
              ? currentQuantity + totalQuantity
              : totalQuantity;
          }
          input.masterBills = [
            {
              number: firstMasterBill.number,
              houseBills: [{ quantity: totalQuantity, number }],
            },
          ];
        } else {
          input.masterBills = [
            {
              number: firstMasterBill.number,
              houseBills: [{ number }],
            },
          ];
        }
      }
    }
  }
  log.info(`Processed ${entryType} Bills for Shipment [${shipmentId}]`, {
    key: `processed${entryType}Bills`,
    id: shipmentId,
  });

  const shipmentIor = ior?.ior;
  if (shipmentIor) {
    let iorInput: UsConsumptionEntryIorInput | GbCustomsEntryIorInput;
    if (arrivalCountry === 'US') {
      // Need `as UsIor` because TS knows iorNumber does not exist on `Ior` (the
      // interface) but we expect this to be a UsIor and the iorNumber (as well as other fields) defined
      const {
        id,
        version,
        name,
        iorNumber,
        addresses,
        duns,
        payerUnitNumber,
        pmsStatus,
        pointOfContact,
        preferredCbpEntryPaymentMethod,
        unknownDuns,
      } = shipmentIor as UsIor;
      iorInput = {
        usIor: { id, version },
        name,
        iorNumber: { type: iorNumber?.type, value: iorNumber?.value },
        address: addresses?.mailing || addresses?.physical,
        duns,
        payerUnitNumber,
        pmsStatus,
        pointOfContact: pointOfContact
          ? pick(pointOfContact, ['name', 'email', 'phone'])
          : undefined,
        preferredPaymentMethod: preferredCbpEntryPaymentMethod,
        unknownDuns,
      } as UsConsumptionEntryIorInput;
    } else {
      const { id, name, vatNumber, eori, addresses } = shipmentIor as
        | GbIor
        | DeIor
        | NlIor
        | FrIor;
      iorInput = {
        ior: { id },
        name,
        vatNumber,
        eori,
        address: addresses?.mailing || addresses?.physical,
      } as
        | GbCustomsEntryIorInput
        | DeCustomsEntryIorInput
        | NlCustomsEntryIorInput
        | FrCustomsEntryIorInput;
    }
    input = { ...input, ior: iorInput } as
      | CreateUsConsumptionEntryInput
      | CreateGbCustomsEntryInput
      | CreateDeCustomsEntryInput
      | CreateNlCustomsEntryInput
      | CreateFrCustomsEntryInput;
  }
  log.info(`Processed ${entryType} Ior for Shipment [${shipmentId}]`, {
    key: `${entryType}IorInput`,
    id: shipmentId,
  });

  const shipmentConsignee = consignee?.consignee;
  if (shipmentConsignee) {
    let consigneeInput;
    if (arrivalCountry === 'US') {
      // Need `as UsConsignee` because TS knows iorNumber does not exist on `Consignee` (the
      // interface) but we expect this to be a UsConsignee and the iorNumber (as well as other fields) defined
      const {
        id,
        version,
        name,
        iorNumber,
        addresses,
        duns,
        pointOfContact,
        unknownDuns,
      } = shipmentConsignee as UsConsignee;
      consigneeInput = {
        usConsignee: { id, version },
        name,
        iorNumber: { type: iorNumber?.type, value: iorNumber?.value },
        address: addresses?.mailing || addresses?.physical,
        duns,
        pointOfContact: pointOfContact
          ? pick(pointOfContact, ['name', 'email', 'phone'])
          : undefined,
        sameAsIor: existingWorkOrder?.consignee?.sameAsIor, // Populate from existing entry data
        unknownDuns,
      } as UsConsumptionEntryConsigneeInput;
    } else {
      const { id, version } = shipmentConsignee as
        | GbConsignee
        | DeConsignee
        | NlConsignee
        | FrConsignee;
      consigneeInput = {
        consignee: { id, version },
        sameAsIor: existingWorkOrder?.consignee?.sameAsIor, // Populate from existing entry data
      } as
        | GbCustomsEntryConsigneeInput
        | DeCustomsEntryConsigneeInput
        | NlCustomsEntryConsigneeInput
        | FrCustomsEntryConsigneeInput;
    }
    input = { ...input, consignee: consigneeInput };
  }
  log.info(`Processed ${entryType} Consignee for Shipment [${shipmentId}]`, {
    key: `${entryType}ConsigneeInput`,
    id: shipmentId,
  });

  let departureInput = {
    exportCountryCode: departure?.country,
    exportDate: departure?.time,
  } as UsConsumptionEntryDepartureInput | CustomsEntryDepartureInput;
  log.info(`Processed ${entryType} Departure for Shipment [${shipmentId}]`, {
    key: `${entryType}DepartureInput`,
    id: shipmentId,
  });

  const shipmentPortOfLading = departure?.portOfLading;
  if (shipmentPortOfLading) {
    const portOfLading = getPortOfLadingFromPortCode(
      shipmentPortOfLading,
      arrivalCountry,
    );
    if (portOfLading) {
      departureInput = {
        ...departureInput,
        ...(arrivalCountry === 'US'
          ? { portOfLadingCode: portOfLading as string }
          : { portOfLading: portOfLading as PortCodeInput }),
      };
    } else {
      log.error(
        `Entry doesn't consume portCode because shipment has portOfLading with type [${shipmentPortOfLading.type}] and value [${shipmentPortOfLading.value}]`,
        {
          string: RecordType.SHIPMENT,
          id: shipmentId,
          key: 'PortCodeMappingMissing',
        },
      );
    }
  }

  let arrivalInput: UsConsumptionEntryArrivalInput | CustomsEntryArrivalInput;
  if (arrivalCountry === 'US') {
    arrivalInput = {
      usDestinationStateCode: shipTo?.address?.stateCode,
      importDate: arrival?.time,
      firmsCode: arrival?.firmsCode,
    };
  } else {
    arrivalInput = {
      country: shipTo?.address?.countryCode,
      importDate: arrival?.time,
    };
  }
  log.info(`Processed ${entryType} Arrival for Shipment [${shipmentId}]`, {
    key: `${entryType}ArrivalInput`,
    id: shipmentId,
  });

  const shipmentPortOfUnlading = arrival?.portOfUnlading;
  if (shipmentPortOfUnlading) {
    const portOfUnlading = getPortOfUnladingFromPortCode(
      shipmentPortOfUnlading,
      arrivalCountry,
    );
    if (portOfUnlading) {
      arrivalInput = {
        ...arrivalInput,
        ...(arrivalCountry === 'US'
          ? { portOfUnladingCode: portOfUnlading as string }
          : { portOfUnlading: portOfUnlading as PortCodeInput }),
      };
    } else {
      log.error(
        `Entry doesn't consume portCode because shipment has portOfUnLading with type [${shipmentPortOfUnlading.type}] and value [${shipmentPortOfUnlading.value}]`,
        {
          string: RecordType.SHIPMENT,
          id: shipmentId,
          key: 'PortCodeMappingMissing',
        },
      );
    }
  }

  const { containerized, conveyanceName, tripNumber, grossWeight } =
    conveyance || {};
  const conveyanceInput = {
    modeOfTransport,
    containerized,
    conveyanceName,
    tripNumber,
    grossWeight,
  } as UsConsumptionEntryConveyanceInput | CustomsEntryConveyanceInput;
  // containerized default value is true for OCEAN modeOfTransport
  if (modeOfTransport === ModeOfTransport.OCEAN) {
    conveyanceInput.containerized = true;
  }
  log.info(`Processed ${entryType} Conyenance for Shipment [${shipmentId}]`, {
    key: `${entryType}ConyenanceInput`,
    id: shipmentId,
  });

  // Need to re-evaluate once we start supported multiple sellers/supplier shipment header.
  if (sellers && sellers.length > 1) {
    throw new Error(
      `Multiple sellers ${sellers.length} on shipment level not supported.`,
    );
  }
  const commercialInvoiceInput = getCommercialInvoiceInput(
    complianceRefToProductMap,
    arrivalCountry || '',
    sellers,
    commercialInvoices,
  );
  log.info(
    `Processed ${entryType} CommercialInvoices for Shipment [${shipmentId}]`,
    {
      key: `${entryType}CommercialInvoiceInput`,
      id: shipmentId,
    },
  );

  input = {
    ...input,
    departure: departureInput,
    arrival: arrivalInput,
    conveyance: conveyanceInput,
    invoices: commercialInvoiceInput,
  } as
    | CreateUsConsumptionEntryInput
    | CreateGbCustomsEntryInput
    | CreateDeCustomsEntryInput
    | CreateNlCustomsEntryInput
    | CreateFrCustomsEntryInput;

  if (arrivalCountry === 'US') {
    const usConsumptionEntryBuyer = {
      name: buyer?.name,
      address: buyer?.address,
      duns: buyer?.duns,
      ein: buyer?.ein,
      buyer: {
        id: buyer?.buyer?.id,
        version: buyer?.buyer?.version,
      },
    } as UsConsumptionEntryBuyerInput;
    input = { ...input, buyer: usConsumptionEntryBuyer };
    log.info(
      `Processed UsConsumptionEntry Buyer for Shipment [${shipmentId}]`,
      {
        key: `UsConsumptionEntryBuyerInput`,
        id: shipmentId,
      },
    );
  }

  const sanitizedInput = cleanDeep(input, {
    emptyStrings: false,
  }) as
    | CreateUsConsumptionEntryInput
    | CreateGbCustomsEntryInput
    | CreateDeCustomsEntryInput
    | CreateNlCustomsEntryInput
    | CreateFrCustomsEntryInput;
  log.info(`Processed ${entryType}Input for Shipment [${shipmentId}]`, {
    key: `${entryType}Input`,
    id: shipmentId,
  });
  return sanitizedInput;
};

const getCustomsBroker = (arrival: ShipmentLegArrival) => {
  return {
    [arrival.country === 'US' ? 'usCustomsBroker' : 'customsBroker']: {
      id: arrival.customsBroker?.customsBroker?.id as string,
    },
  };
};

const getEntryProductLines = (
  complianceDetail: ShipmentComplianceDetail,
  complianceRefToProductMap: ComplianceRefToProductMap,
  arrivalCountry: string,
) => {
  const { complianceDetailsReference, quantity, poNumber } = complianceDetail;
  const complianceRefKey = getComplianceRefKey(complianceDetail);
  const product = complianceRefToProductMap[complianceRefKey];
  log.debug(
    `Product fetched from compliance map for key ${complianceRefKey} is ${JSON.stringify(
      product,
    )}`,
  );
  if (!product) return undefined;
  const productLines = constructEntryProductLines(
    complianceDetailsReference,
    product,
    arrivalCountry,
    poNumber,
  );
  // Currencies for unit price across different lines.
  const currencies = new Set();

  const linesValue = productLines.reduce((sum, line) => {
    const unitValue = last(
      line.tariffs as
        | UsConsumptionEntryTariffInput[]
        | GbCustomsEntryTariffInput[]
        | DeCustomsEntryTariffInput[]
        | NlCustomsEntryTariffInput[]
        | FrCustomsEntryTariffInput[],
    )?.unitValue;
    if (unitValue) currencies.add(unitValue?.currency);
    return sum + (unitValue?.value || 0);
  }, 0);

  const linesAssist = productLines.reduce((sum, line) => {
    const unitAssist = last(
      line.tariffs as
        | UsConsumptionEntryTariffInput[]
        | GbCustomsEntryTariffInput[]
        | DeCustomsEntryTariffInput[]
        | NlCustomsEntryTariffInput[]
        | FrCustomsEntryTariffInput[],
    )?.unitAssist;
    if (unitAssist) currencies.add(unitAssist?.currency);
    return sum + (unitAssist?.value || 0);
  }, 0);

  if (linesValue && currencies.size !== 1) {
    throw new Error(
      `Multiple currencies ${currencies.size} not supported across different lines.`,
    );
  }

  const { id, version } = product;
  const currency = currencies.values().next().value;
  const totalValueField =
    quantity && Boolean(linesValue)
      ? {
          totalValue: {
            currency,
            value: roundNumber(linesValue * quantity, PRECISION),
          },
        }
      : {};

  const totalAssistField =
    quantity && Boolean(linesAssist)
      ? {
          totalAssist: {
            currency,
            value: roundNumber(linesAssist * quantity, PRECISION),
          },
        }
      : {};

  return {
    product: { id, version },
    quantity: quantity,
    lines: productLines,
    ...totalValueField,
    ...totalAssistField,
  } as
    | UsConsumptionEntryProductInput
    | GbCustomsEntryProductInput
    | DeCustomsEntryProductInput
    | NlCustomsEntryProductInput
    | FrCustomsEntryProductInput;
};

const constructEntryProductLines = (
  complianceDetailsReference: ExternalComplianceReference,
  product: Product,
  arrivalCountry: string,
  poNumber: string | undefined,
) => {
  const productLines:
    | ProductUsEntryLine[]
    | ProductGbEntryLine[]
    | ProductDeEntryLine[]
    | ProductNlEntryLine[]
    | ProductFrEntryLine[] = _.get(
    product.complianceDetails || {},
    [lowerCase(arrivalCountry), 'entryLines'],
    [],
  );
  return (
    productLines.map((productLine) => {
      const supplier = productLine.manufacturer;
      const { addresses, pointOfContact, complianceDetails, name } =
        supplier || {};
      return {
        ...productLine,
        ...(supplier && {
          manufacturer: {
            address: addresses?.mailing || addresses?.physical,
            name,
            supplier: pick(supplier, ['id', 'version']),
            ...(arrivalCountry === 'US' && {
              pointOfContact:
                pointOfContact &&
                pick(pointOfContact, ['name', 'email', 'phone']),
              mid: complianceDetails?.us?.mid,
            }),
          },
        }),
        poNumber: poNumber,
        externalReference: complianceDetailsReference,
      } as
        | UsConsumptionEntryLineInput
        | GbCustomsEntryLineInput
        | DeCustomsEntryLineInput
        | NlCustomsEntryLineInput
        | FrCustomsEntryLineInput;
    }) || []
  );
};

const getCommercialInvoiceInput = (
  complianceRefToProductMap: ComplianceRefToProductMap,
  arrivalCountry: string,
  sellers?: ShipmentSeller[],
  commercialInvoices?: ShipmentCommercialInvoice[],
): SupportedEntryCommercialInvoiceInput[] | undefined => {
  const commercialInvoiceSeller = sellers?.[0];
  const { name, address, seller } = commercialInvoiceSeller || {};
  return commercialInvoices?.map(({ invoiceNumber, complianceDetails }) => {
    const products =
      complianceDetails?.map((complianceDetail) =>
        getEntryProductLines(
          complianceDetail,
          complianceRefToProductMap,
          arrivalCountry,
        ),
      ) || [];

    const currency = products?.reduce((currency, product) => {
      if (currency !== '') return currency;
      return (
        product?.totalValue?.currency || product?.totalAssist?.currency || ''
      );
    }, '');

    const invoiceValue = products?.reduce((sum, product) => {
      return sum + (product?.totalValue?.value || 0);
    }, 0);

    const invoiceAssist = products?.reduce((sum, product) => {
      return sum + (product?.totalAssist?.value || 0);
    }, 0);

    return {
      invoiceNumber,
      products,
      ...(commercialInvoiceSeller && {
        seller: {
          name,
          address,
          supplier: { id: seller?.id, version: seller?.version },
          ...(arrivalCountry === 'US' && {
            mid: (seller as any)?.mid,
          }),
        },
      }),
      ...(invoiceValue && {
        value: {
          value: invoiceValue,
          currency,
        },
      }),
      ...(invoiceAssist && {
        assist: {
          value: invoiceAssist,
          currency,
        },
      }),
      // Setting currency rate only for USD since for other currencies,
      // operators fill in the live rate from external websites currently.
      ...(currency === 'USD' && { currencyRate: 1 }),
    } as SupportedEntryCommercialInvoiceInput;
  });
};
