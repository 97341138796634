import { WorkOrderTaskType } from '__generated__/graphql';

export const isExamOrHoldWorkOrderTask = (
  taskType: WorkOrderTaskType | string | undefined,
): boolean => {
  return (
    taskType != undefined &&
    [
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_CBP_EXAM,
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_CBP_HOLD,
      WorkOrderTaskType.US_CONSUMPTION_ENTRY_PGA_HOLD,
      WorkOrderTaskType.US_TYPE86_ENTRY_CBP_EXAM,
      WorkOrderTaskType.US_TYPE86_ENTRY_CBP_HOLD,
      WorkOrderTaskType.US_TYPE86_ENTRY_PGA_HOLD,
    ].includes(taskType as WorkOrderTaskType)
  );
};
