import { RecordType } from '@xbcb/shared-types';
import { AppRecordRoute } from 'routes/types';
import { transformExchangeMessage } from 'libs/formTransforms/exchangeMessage';
import { InboxEmail } from 'routes/loadableComponents';
import { queryExchangeMessage } from './exchangeMessageQueries.query';
import { ModalKey } from 'types/modals';
import { setModal } from 'actions';

const exchangeMessageRoutes: AppRecordRoute[] = [
  {
    Page: InboxEmail,
    recordType: RecordType.EXCHANGE_MESSAGE,
    hideDefaultEditButton: () => true,
    transformRecordToInput: transformExchangeMessage.toForm,
    kebabMenuItems: [
      {
        key: 'assignEmailToWorkOrder',
        text: 'Assign to WorkOrder',
        action: ({ dispatch }) => {
          dispatch(
            setModal({
              key: ModalKey.ASSIGN_EMAIL_TO_WORK_ORDER,
              props: { visible: true },
            }),
          );
        },
      },
    ],
    queries: { get: queryExchangeMessage },
    getTitleField: () => 'subject',
  },
];

export default exchangeMessageRoutes;
