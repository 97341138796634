import { SupportedCountryAbbreviation } from '@xbcb/shared-types';
import { setModal } from 'actions';
import { ModalKey } from 'types';
import { CustomsDeclaration } from '__generated__/graphql';
import { getImportCountryCodeForCuDe } from 'libs/getImportCountryCodeForCuDe';
import { AppRecordKebabMenuItem } from '../../../../types';

const enabledCountries: SupportedCountryAbbreviation[] = [
  SupportedCountryAbbreviation.IN,
];

/**
 * Show the kebab menu item if the import country of the CuDe should see this option
 * @return {boolean}
 */
const show: AppRecordKebabMenuItem['show'] = ({ existingRecord }) => {
  const importCountryCode = getImportCountryCodeForCuDe(
    existingRecord as CustomsDeclaration,
  );
  return enabledCountries.includes(
    SupportedCountryAbbreviation[
      importCountryCode as keyof typeof SupportedCountryAbbreviation
    ],
  );
};

const action: AppRecordKebabMenuItem['action'] = async ({ dispatch }) => {
  dispatch(
    setModal({
      key: ModalKey.SEND_DOCUMENT,
      props: {
        document: null, // null to trigger document selection
        visible: true,
      },
    }),
  );
};

export const sendDocumentsToBroker: AppRecordKebabMenuItem = {
  show,
  action,
  key: 'sendDocumentsToBroker',
  text: 'Send Documents To Broker',
};
