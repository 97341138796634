import { RecordType } from '@xbcb/shared-types';
import { WorkOrderType } from '@xbcb/work-order-types';
import CustomsEntrySecondaryHeader from 'components/CustomsEntrySecondaryHeader';
import { CustomsDeclaration } from '../../../loadableComponents';
import {
  getSharedCustomsEntrySubmitButtons,
  sharedCustomsEntryKebabMenuItems,
} from '../../utils';
import { WorkOrderRoute } from '../.';
import { sendDocumentsToBroker } from './kebabMenuItems';

import {
  queryCustomsDeclaration,
  updateCustomsDeclaration,
} from './customsDeclaration.query';
import { transformCustomsDeclaration } from '../../../../libs/formTransforms/workOrder/customsDeclaration';

const customsDeclarationRoute: WorkOrderRoute = {
  Page: CustomsDeclaration,
  recordType: RecordType.CUSTOMS_DECLARATION,
  queries: { get: queryCustomsDeclaration, update: updateCustomsDeclaration },
  SecondaryHeaderContents: [CustomsEntrySecondaryHeader],
  transformUpdateRecordInput: transformCustomsDeclaration.toSchema,
  defaultEditMode: false,
  kebabMenuItems: [...sharedCustomsEntryKebabMenuItems, sendDocumentsToBroker],
  submitButtons: getSharedCustomsEntrySubmitButtons(
    WorkOrderType.CustomsDeclaration,
  ),
};

export default customsDeclarationRoute;
