import React from 'react';
import { camelCase } from 'change-case';
import SiderMenu from 'components/SiderMenu';
import { NavLink, useLocation } from 'react-router-dom';
import { useCurrentUser, CurrentUser } from 'libs/hooks';
import { checkAccess } from '@xbcb/client-utils';
import {
  getRecordType,
  getCountryAbbreviationFromCustomsBrokerId,
} from '@xbcb/core';
import { formatRecordName } from '@xbcb/js-utils';
import {
  ObjectType,
  AccountType,
  AnyObject,
  RecordType,
  WorkOrderRecordType,
  PartyRecordType,
} from '@xbcb/shared-types';
import { Loading } from '@xbcb/feedback-components';
import { getEnv, locationToObjectType, safeGetMessage } from '@xbcb/ui-utils';
import {
  DataCyPrefix,
  MessageBundle,
  createDataCyValue,
  uiStageToBackendStage,
} from '@xbcb/ui-types';
import { StyledMenuItem, StyledSubMenu } from './styles';
import {
  formatWorkOrderCountryAbbreviation,
  formatIorCountryAbbreviation,
} from 'libs/formatCountryAbbreviation';
import {
  ESCALATION_MESSAGE_SEARCH_FEATURE,
  escalationMessageSearchFeatureName,
} from '@xbcb/feature-flags';
import { euCountries } from 'types/euCountriesList';
import { restrictedDeployment } from 'libs/restrictedDeployment';
import { isGlitchWatchUiAdminViewEnabled } from 'libs/featureFlags';
import {
  SolutionOutlined,
  TruckOutlined,
  ApartmentOutlined,
  FileTextOutlined,
  WalletOutlined,
  ProductOutlined,
  SettingOutlined,
  ToolOutlined,
  MailOutlined,
} from 'icons';
import { isGenericBroker } from 'libs/isGenericBroker';
import { useBundle } from '@amzn/react-arb-tools';
import { isCommodityGroupViewEnabled } from 'libs/featureFlags/commodityGroupFeatureFlags';

const processingErrorsPath = '/processing-errors';

interface SubMenuItem {
  text: string;
  path: string;
  icon?: React.ReactNode;
  hide?: boolean;
  visibleToAccountTypes?: AccountType[];
  visibleToCustomsBrokerRecordTypes?: RecordType[];
  additionalPermissionsNeeded?: ObjectType[];
}

interface MenuItemProps {
  text: string;
  path?: string;
  icon?: React.ReactNode;
  items?: SubMenuItem[];
  visibleToAccountTypes?: AccountType[];
  noPermissionNeeded?: boolean;
  visibleToCustomsBrokerRecordTypes?: RecordType[];
  additionalPermissionsNeeded?: ObjectType[];
}

const brokersWithBulkUploadAccess = [
  RecordType.DE_CUSTOMS_BROKER,
  RecordType.FR_CUSTOMS_BROKER,
  RecordType.NL_CUSTOMS_BROKER,
  RecordType.UK_CUSTOMS_BROKER,
  RecordType.BROKER,
];

const menuItem: React.FC<MenuItemProps> = ({
  text,
  path,
  items,
  icon,
}: MenuItemProps) => {
  if (items) {
    return (
      <StyledSubMenu
        key={text}
        title={<span>{text}</span>}
        icon={icon}
        data-cy={createDataCyValue(
          DataCyPrefix.APP_SIDER_MENU_SUB_MENU,
          camelCase(text),
        )}
      >
        {items.map(({ path: itemPath, text: itemText }) => (
          <StyledMenuItem
            key={itemPath}
            data-cy={createDataCyValue(
              DataCyPrefix.APP_SIDER_MENU_SUB_MENU_ITEM,
              camelCase(itemText),
            )}
          >
            <NavLink to={itemPath} title={itemText}>
              {itemText}
            </NavLink>
          </StyledMenuItem>
        ))}
      </StyledSubMenu>
    );
  }
  return (
    <StyledMenuItem
      key={path}
      icon={icon}
      data-cy={createDataCyValue(
        DataCyPrefix.APP_SIDER_MENU_ITEM,
        camelCase(text),
      )}
    >
      <NavLink to={path || ''}>{text}</NavLink>
    </StyledMenuItem>
  );
};

export const getMenuItems = (
  accountType: AccountType,
  user: AnyObject,
  bundle: MessageBundle,
): MenuItemProps[] => {
  const customsBrokerId = user.customsBroker?.id;
  const countryOfOperation =
    customsBrokerId &&
    getCountryAbbreviationFromCustomsBrokerId(customsBrokerId);
  const isEnabledInEU = euCountries.includes(countryOfOperation);
  const hideLegacyUtilityOptions =
    isEnabledInEU || isGenericBroker(customsBrokerId);

  const isEscalationMessageSearchEnabled =
    ESCALATION_MESSAGE_SEARCH_FEATURE.isEnabled(
      escalationMessageSearchFeatureName,
      { stage: uiStageToBackendStage[getEnv().stage] },
    );

  // Setup helper function with accountType and pluralize already provided
  const formatMenuItemText = (recordType: RecordType) =>
    formatRecordName({ recordType, accountType, pluralize: true });

  const formatIorText = (recordType: PartyRecordType) => {
    const countryAbbreviation = formatIorCountryAbbreviation({
      user,
      recordType,
    });
    return `${countryAbbreviation}${formatMenuItemText(recordType)}`;
  };

  const formatEntryText = (recordType: WorkOrderRecordType) => {
    const countryAbbreviation =
      // For forwarder and shipper account, Us Entries should just be listed as 'Entries' for now
      // until we offer EU to distinguish. And we know based off the visibleToAccountTypes provided below to EU entries
      // only one will show for non-operators for now, which is US.
      accountType === AccountType.OPERATOR
        ? formatWorkOrderCountryAbbreviation({
            user,
            recordType,
          })
        : '';
    return `${countryAbbreviation}${formatMenuItemText(recordType)}`;
  };

  const processingErrorItems = isGlitchWatchUiAdminViewEnabled(
    user as CurrentUser,
  )
    ? [
        {
          text: formatMenuItemText(RecordType.PROCESSING_ERROR),
          path: processingErrorsPath,
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
      ]
    : [];

  const siderMenu: MenuItemProps[] = [
    {
      text: formatMenuItemText(RecordType.SHIPMENT),
      path: '/shipments',
      icon: <TruckOutlined />,
      visibleToCustomsBrokerRecordTypes: [RecordType.US_CUSTOMS_BROKER], // Shipments should only be visible to US Customs Brokers
    },
    {
      text: safeGetMessage(bundle, 'parties'),
      icon: <ApartmentOutlined />,
      items: [
        {
          text: formatMenuItemText(RecordType.SHIPPER),
          path: '/shippers',
          visibleToAccountTypes: [AccountType.FORWARDER, AccountType.OPERATOR],
        },
        {
          text: formatIorText(RecordType.US_IOR),
          path: '/us-iors',
        },
        {
          text: formatIorText(RecordType.GB_IOR),
          path: '/gb-iors',
        },
        {
          text: formatIorText(RecordType.DE_IOR),
          path: '/de-iors',
        },
        {
          text: formatIorText(RecordType.NL_IOR),
          path: '/nl-iors',
        },
        {
          text: formatIorText(RecordType.FR_IOR),
          path: '/fr-iors',
        },
        {
          text: formatMenuItemText(RecordType.FORWARDER),
          path: '/forwarders',
        },
        {
          text: formatMenuItemText(RecordType.US_CONSIGNEE),
          path: '/us-consignees',
        },
        {
          text: formatMenuItemText(RecordType.CUSTOMS_AGENT),
          path: '/customs-agents',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatMenuItemText(RecordType.FACILITY),
          path: '/facilities',
        },
        {
          text: formatMenuItemText(RecordType.SUPPLIER),
          path: '/suppliers',
        },
        {
          text: formatMenuItemText(RecordType.TRUCKER),
          path: '/truckers',
        },
      ],
    },
    {
      text: safeGetMessage(bundle, 'work_orders'),
      icon: <FileTextOutlined />,
      items: [
        {
          text: formatMenuItemText(RecordType.CUSTOMS_DECLARATION),
          path: '/customs-declarations',
          visibleToAccountTypes: [AccountType.OPERATOR],
          hide: restrictedDeployment(RecordType.CUSTOMS_DECLARATION),
        },
        {
          text: formatEntryText(RecordType.DE_CUSTOMS_ENTRY),
          path: '/de-customs-entries',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatEntryText(RecordType.GB_CUSTOMS_ENTRY),
          path: '/gb-customs-entries',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatEntryText(RecordType.NL_CUSTOMS_ENTRY),
          path: '/nl-customs-entries',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatEntryText(RecordType.FR_CUSTOMS_ENTRY),
          path: '/fr-customs-entries',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatEntryText(RecordType.US_CONSUMPTION_ENTRY),
          path: '/us-consumption-entries',
        },
        {
          text: formatEntryText(RecordType.US_TYPE86_ENTRY),
          path: '/us-type86-entries',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatMenuItemText(RecordType.US_POST_SUMMARY_CORRECTION),
          path: '/us-post-summary-corrections',
        },
        {
          text: formatMenuItemText(RecordType.US_IN_BOND),
          path: '/us-in-bonds',
        },
        {
          text: formatMenuItemText(RecordType.US_IOR_CONTINUOUS_BOND_REQUEST),
          path: '/us-ior-continuous-bond-requests',
        },
        {
          text: formatMenuItemText(RecordType.US_ISF),
          path: '/us-isfs',
        },
        {
          text: formatMenuItemText(RecordType.IMPORTER_ENTITY_ACTIVATION),
          path: '/importer-entity-activations',
          visibleToAccountTypes: [AccountType.OPERATOR],
          hide: restrictedDeployment(RecordType.IMPORTER_ENTITY_ACTIVATION),
        },
        {
          text: formatEntryText(RecordType.DE_IOR_ACTIVATION),
          path: '/de-ior-activations',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatEntryText(RecordType.GB_IOR_ACTIVATION),
          path: '/gb-ior-activations',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatEntryText(RecordType.NL_IOR_ACTIVATION),
          path: '/nl-ior-activations',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatEntryText(RecordType.FR_IOR_ACTIVATION),
          path: '/fr-ior-activations',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatEntryText(RecordType.US_IOR_ACTIVATION),
          path: '/us-ior-activations',
          visibleToAccountTypes: [AccountType.OPERATOR],
        },
        {
          text: formatMenuItemText(RecordType.DELIVERY_ORDER),
          path: '/delivery-orders',
        },
      ],
    },
    {
      text: formatMenuItemText(RecordType.INVOICE),
      path: '/invoices',
      icon: <WalletOutlined />,
    },
    {
      text: formatMenuItemText(RecordType.PRODUCT),
      path: '/products',
      icon: <ProductOutlined />,
    },
    {
      text: safeGetMessage(bundle, 'inbox'),
      path: '/exchange-messages',
      visibleToAccountTypes: [AccountType.OPERATOR],
      additionalPermissionsNeeded: [ObjectType.EXCHANGE_MESSAGE],
      icon: <MailOutlined />,
    },
    {
      text: safeGetMessage(bundle, 'classifications'),
      path: '/classifications',
      visibleToAccountTypes: [AccountType.SHIPPER, AccountType.FORWARDER],
      noPermissionNeeded: true,
    },
    {
      text: safeGetMessage(bundle, 'settings'),
      icon: <SettingOutlined />,
      items: [
        {
          text: safeGetMessage(bundle, 'profile'),
          path: '/profile',
        },
        {
          text: safeGetMessage(bundle, 'templates'),
          path: '/document-templates',
        },
        {
          text: formatMenuItemText(RecordType.ASSIGNMENT_TEAM),
          path: '/assignment-teams',
        },
        {
          text: formatMenuItemText(RecordType.COMMODITY_GROUP),
          path: '/commodity-groups',
          visibleToAccountTypes: [AccountType.OPERATOR],
          hide: !isCommodityGroupViewEnabled,
        },
        {
          text: formatMenuItemText(RecordType.BUSINESS_SUPPORT_TEAM),
          path: '/business-support-teams',
        },
        {
          text: formatMenuItemText(RecordType.SUBJECT_MATTER_EXPERT_TEAM),
          path: '/subject-matter-expert-teams',
          visibleToCustomsBrokerRecordTypes: [RecordType.US_CUSTOMS_BROKER],
        },
        {
          text: safeGetMessage(bundle, 'company'),
          path: '/company',
          visibleToAccountTypes: [AccountType.FORWARDER, AccountType.SHIPPER],
        },
        {
          text: formatMenuItemText(RecordType.OPERATOR_USER),
          path: '/operator-users',
        },
        {
          text: formatMenuItemText(RecordType.FORWARDER_USER),
          path: '/forwarder-users',
        },
        {
          text: formatMenuItemText(RecordType.SHIPPER_USER),
          path: '/shipper-users',
        },
      ],
    },
    {
      text: safeGetMessage(bundle, 'utilities'),
      icon: <ToolOutlined />,
      items: [
        {
          text: formatMenuItemText(RecordType.BULK_CHARGES_UPLOAD_REQUEST),
          path: '/bulk-charges-upload-requests',
          visibleToAccountTypes: [AccountType.OPERATOR],
          visibleToCustomsBrokerRecordTypes: brokersWithBulkUploadAccess,

          additionalPermissionsNeeded: [ObjectType.BULK_CHARGES_UPLOAD_REQUEST],
        },
        {
          text: formatMenuItemText(RecordType.BULK_COMPLIANCE_UPLOAD_REQUEST),
          path: '/bulk-compliance-upload-requests',
          visibleToAccountTypes: [AccountType.OPERATOR],
          visibleToCustomsBrokerRecordTypes: brokersWithBulkUploadAccess,
          additionalPermissionsNeeded: [
            ObjectType.BULK_COMPLIANCE_UPLOAD_REQUEST,
          ],
        },
        {
          text: formatMenuItemText(RecordType.BULK_MILESTONE_UPLOAD_REQUEST),
          path: '/bulk-milestone-upload-requests',
          visibleToAccountTypes: [AccountType.OPERATOR],
          visibleToCustomsBrokerRecordTypes: brokersWithBulkUploadAccess,

          additionalPermissionsNeeded: [
            ObjectType.BULK_MILESTONE_UPLOAD_REQUEST,
          ],
        },
        {
          text: safeGetMessage(bundle, 'queries'),
          path: '/queries',
          hide: hideLegacyUtilityOptions,
        },
        {
          text: formatMenuItemText(RecordType.REPORT_RECONCILIATION_REQUEST),
          path: '/report-reconciliation-requests',
          hide: hideLegacyUtilityOptions,
        },
        {
          text: safeGetMessage(bundle, 'assignments'),
          path: '/assignments',
          icon: <SolutionOutlined />,
          visibleToAccountTypes: [AccountType.OPERATOR],
          additionalPermissionsNeeded: [ObjectType.ASSIGNMENT_TEAM],
          hide: hideLegacyUtilityOptions,
        },
        {
          text: safeGetMessage(bundle, 'escalation_messages'),
          path: '/work-order-task-escalation-messages',
          visibleToCustomsBrokerRecordTypes: [RecordType.US_CUSTOMS_BROKER],
          hide: !isEscalationMessageSearchEnabled,
        },
        ...processingErrorItems,
      ],
    },
  ];

  siderMenu.unshift({
    text: safeGetMessage(bundle, 'assignment_workspace'),
    path: '/assignment-workspace',
    icon: <SolutionOutlined />,
    visibleToAccountTypes: [AccountType.OPERATOR],
  });

  return siderMenu;
};

const AppSiderMenu = (): JSX.Element => {
  const [bundle] = useBundle('components.AppSiderMenu');
  const { pathname } = useLocation();
  const user = useCurrentUser();
  const { loading, accountType, account } = user;

  if (loading) return <Loading isLoading />;
  const menuItemsFiltered = getMenuItems(accountType, user, bundle).reduce(
    (filteredMenuItems: MenuItemProps[], menuItem) => {
      const menuItemShallowCopy = Object.assign({}, menuItem);
      if (
        menuItem.visibleToAccountTypes &&
        !menuItem.visibleToAccountTypes.includes(accountType)
      ) {
        return filteredMenuItems;
      }

      const customsBrokerRecordType =
        (user.customsBroker?.id && getRecordType(user.customsBroker?.id)) ||
        RecordType.US_CUSTOMS_BROKER; // Shipper and forwarder users don't have a customs broker. They are US only so we should fallback accordingly
      if (
        menuItem.visibleToCustomsBrokerRecordTypes &&
        !menuItem.visibleToCustomsBrokerRecordTypes.includes(
          customsBrokerRecordType,
        )
      ) {
        return filteredMenuItems;
      }

      if (menuItem.items) {
        menuItemShallowCopy.items = menuItem.items.filter((item) => {
          // There may be a specific condition (most likely a stage) where we
          // want to hide the menu item, if so we should filter it out. Thus, we
          //  don't even need to checkAccess since we won't show it anyways
          if (item.hide) return false;

          if (
            item.visibleToAccountTypes &&
            !item.visibleToAccountTypes.includes(accountType)
          )
            return false;

          if (
            item.visibleToCustomsBrokerRecordTypes &&
            !item.visibleToCustomsBrokerRecordTypes.includes(
              customsBrokerRecordType,
            )
          )
            return false;

          if (
            item.additionalPermissionsNeeded &&
            !item.additionalPermissionsNeeded.reduce(
              (hasAccess, objectType) =>
                hasAccess && checkAccess(user, objectType, 'EXPLICIT_READ'),
              true,
            )
          )
            return false;

          if (
            ['/classifications', '/profile', processingErrorsPath].includes(
              item.path,
            )
          )
            return true;

          // queries available to customers with plans
          if (
            item.path === '/queries' &&
            (accountType === AccountType.OPERATOR ||
              account?.billingDetails?.subscriptionPlan)
          )
            return true;

          const objectType =
            item.path === '/company'
              ? accountType
              : locationToObjectType(item.path);

          return objectType
            ? checkAccess(user, objectType, 'EXPLICIT_READ')
            : false;
        });

        if (menuItemShallowCopy.items.length) {
          filteredMenuItems.push(menuItemShallowCopy);
        }
      } else if (menuItem.noPermissionNeeded) {
        filteredMenuItems.push(menuItemShallowCopy);
      } else {
        const objectType = menuItem.path
          ? locationToObjectType(menuItem.path)
          : undefined;
        const hasAccess = objectType
          ? checkAccess(user, objectType, 'EXPLICIT_READ')
          : false;
        menuItem.additionalPermissionsNeeded?.reduce(
          (hasAccess, objectType) =>
            hasAccess && checkAccess(user, objectType, 'EXPLICIT_READ'),
          hasAccess,
        );

        // Explicitly allowing shipments is a temporary solution until we have a homepage/dashbord that all users can see.
        if (objectType === ObjectType.SHIPMENT || hasAccess) {
          filteredMenuItems.push(menuItemShallowCopy);
        }
      }
      return filteredMenuItems;
    },
    [],
  );
  return (
    <SiderMenu selectedKeys={[pathname]}>
      {menuItemsFiltered.map((a) => menuItem({ ...a }))}
    </SiderMenu>
  );
};

export default AppSiderMenu;
