import React from 'react';
import { message } from 'antd';
import { paramCase } from 'change-case';
import { FormInstance } from 'antd/lib/form';
import { History } from 'history';
import * as uuid from 'uuid';
import type { InvoiceLinesRequest } from '@xbcb/api-gateway-client';
import { cleanObject, reportError, validateForm } from '@xbcb/ui-utils';
import { formatRecordName, pluralize } from '@xbcb/js-utils';
import { getRecordFromResponse } from '@xbcb/shared-queries';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { NamePath, AdditionalFormError } from '@xbcb/ui-types';

export type OnClick = (event: React.MouseEvent<HTMLElement>) => void;

interface OnCreateClicked {
  form: FormInstance;
  setLoading?: any;
  history?: History;
  recordType: RecordType;
  validateCreate?: any;
  transformCreateRecordInput?: any;
  createRecord: any;
  clean?: boolean;
  closeModal?: any;
  additionalInputValues?: { [key: string]: any };
  operatorId?: string;
  customRedirectUrl?: (record: AnyObject) => Promise<string>; // method to fetch custom url based on the record.
  user: AnyObject;
  shipperId?: string;
  onCreate?: (value: any) => void;
  closeOnFailure?: boolean;
}
const handleRecordCreate = async ({
  form,
  setLoading,
  history,
  recordType,
  validateCreate,
  transformCreateRecordInput,
  createRecord,
  clean = true,
  closeModal,
  additionalInputValues,
  // This is defined separately due to the UnplannedCharges component which uses the
  // operatorId specified on the record instead of the user
  operatorId: inputOperatorId,
  customRedirectUrl: customRedirect,
  user,
  shipperId,
  onCreate,
  closeOnFailure = true,
}: OnCreateClicked): Promise<InvoiceLinesRequest | undefined> => {
  if (setLoading) setLoading(true);
  try {
    const { accountType } = user;
    const operatorId = inputOperatorId || user?.operator?.id;
    const validateParams: {
      form: FormInstance;
      additionalErrors?: AdditionalFormError[];
      validateFields?: NamePath[];
    } = { form };

    // additional custom validation
    if (validateCreate) {
      const { additionalErrors, validateFields } = await validateCreate({
        input: form.getFieldsValue(),
        operatorId,
      });
      validateParams.additionalErrors = additionalErrors;
      validateParams.validateFields = validateFields;
    }
    const valid = await validateForm(validateParams);
    if (valid) {
      let formValues = form.getFieldsValue();
      if (additionalInputValues) {
        formValues = { ...additionalInputValues, ...formValues };
      }
      let input = JSON.parse(JSON.stringify(formValues));
      if (clean) input = cleanObject(formValues);
      if (transformCreateRecordInput) {
        // DO NOT REMOVE `isCreate`, it can be used by the transform
        // functions that care about whether it's create vs update
        // for example: packages/react/src/libs/formTransforms/user.ts
        input = await transformCreateRecordInput({
          input,
          isCreate: true,
          recordType,
          user,
        });
      }

      // for these record types, don't add operator ids since they
      // are not required for creation
      const nonOperatorRecords: RecordType[] = [RecordType.COMMODITY_GROUP];
      if (!nonOperatorRecords.includes(recordType)) {
        input.operator = { id: operatorId };
      }

      // for these record types, dont add shipper ids,
      // they are not needed for creation(shipper)
      const noShippersAddRecords: RecordType[] = [RecordType.SHIPPER];

      // These records are associated with a single shipper
      const singleShipperInputRecordTypes: RecordType[] = [RecordType.PRODUCT];
      if (shipperId) {
        const shipper = { id: shipperId };
        if (
          !noShippersAddRecords.includes(recordType) &&
          !singleShipperInputRecordTypes.includes(recordType)
        ) {
          input.shippers = [shipper];
        }
      }
      if (singleShipperInputRecordTypes.includes(recordType)) {
        input.shipper = {
          id: input?.shipper?.id || shipperId || user?.shipper?.id,
        };
      }
      const response = await createRecord({
        variables: {
          idempotencyKey: uuid.v4(), // TODO come up with a better one
          input,
        },
      });

      const record = getRecordFromResponse(response, 'create', recordType);
      if (onCreate && record) onCreate(record);

      const { name } = formValues;
      message.success(
        `New ${formatRecordName({ recordType, accountType })} ${
          name ? `"${name}" ` : ''
        }created`,
        5.0,
      );

      const customUrl = customRedirect && (await customRedirect(record));

      // customRedirect may perform a query of some type, thus we must wait for it to be done before we close the modal.
      if (closeModal) closeModal();
      if (history) {
        history.push(
          customUrl || `/${pluralize(paramCase(recordType))}/${record.id}`, // fallback to default behavior if custom url is not present.
        );
      }
      return record;
    }
  } catch (e) {
    reportError(e);
    // TODO improve error handling to the user
    // TODO hide error message in production
    message.error(`Sorry, an error occurred: ${(e as any).message}`);
    if (closeModal && closeOnFailure) closeModal();
  }
  if (setLoading) setLoading(false);
  return;
};

export default handleRecordCreate;
