import { AnyObject, RecordType } from '@xbcb/shared-types';
import { AppRecordRoute } from '../types';
import {
  Team,
  SubjectMatterExpertTeam,
  BusinessSupportTeam,
  CommodityGroup,
} from '../loadableComponents';
import {
  transformAssignmentTeam,
  transformSubjectMatterExpertTeam,
} from 'libs/formTransforms';
import { checkAccess } from '@xbcb/client-utils';
import { CrudPermissionName } from '@xbcb/client-types';
import {
  assignmentTeamFragments,
  subjectMatterExpertTeamFragments,
  businessSupportTeamFragments,
} from '@xbcb/client-queries';
import { commodityGroupFragments } from '@xbcb/trade-data-queries';
import { validateAssignmentTeamUpdate } from 'libs/validateAssignmentTeam';
import { validateCommodityGroupUpdate } from 'libs/validateCommodityGroup';
import { transformBusinessSupportTeam } from 'libs/formTransforms/businessSupportTeam';
const teamRoutes: AppRecordRoute[] = [
  {
    Page: Team,
    recordType: RecordType.ASSIGNMENT_TEAM,
    fields: `...assignmentTeamFields`,
    fragments: assignmentTeamFragments,
    transformUpdateRecordInput: transformAssignmentTeam.toSchema,
    transformRecordToInput: transformAssignmentTeam.toForm,
    validateUpdate: validateAssignmentTeamUpdate,
    status: {
      show: ({ currentUser }: AnyObject): boolean => {
        return checkAccess(
          currentUser,
          RecordType.ASSIGNMENT_TEAM,
          CrudPermissionName.ADMIN,
        );
      },
    },
  },
  {
    Page: BusinessSupportTeam,
    recordType: RecordType.BUSINESS_SUPPORT_TEAM,
    fields: `...businessSupportTeamFields`,
    fragments: businessSupportTeamFragments,
    transformUpdateRecordInput: transformBusinessSupportTeam.toSchema,
    transformRecordToInput: transformBusinessSupportTeam.toForm,

    status: {
      show: ({ currentUser }: AnyObject): boolean => {
        return checkAccess(
          currentUser,
          RecordType.BUSINESS_SUPPORT_TEAM,
          CrudPermissionName.ADMIN,
        );
      },
    },
  },
  {
    Page: SubjectMatterExpertTeam,
    recordType: RecordType.SUBJECT_MATTER_EXPERT_TEAM,
    fields: `...subjectMatterExpertTeamFields`,
    fragments: subjectMatterExpertTeamFragments,
    transformUpdateRecordInput: transformSubjectMatterExpertTeam.toSchema,
    transformRecordToInput: transformSubjectMatterExpertTeam.toForm,
    status: {
      show: ({ currentUser }: AnyObject): boolean => {
        return checkAccess(
          currentUser,
          RecordType.SUBJECT_MATTER_EXPERT_TEAM,
          CrudPermissionName.ADMIN,
        );
      },
    },
  },
  {
    Page: CommodityGroup,
    recordType: RecordType.COMMODITY_GROUP,
    fields: `...commodityGroupFields`,
    fragments: commodityGroupFragments,

    validateUpdate: validateCommodityGroupUpdate,
    status: {
      show: ({ currentUser }: AnyObject): boolean => {
        return checkAccess(
          currentUser,
          RecordType.ASSIGNMENT_TEAM,
          CrudPermissionName.ADMIN,
        );
      },
    },
  },
];

export default teamRoutes;
