import { showValidationErrors } from '@xbcb/ui-utils';
import { KebabMenuItemKey, AdditionalFormError } from '@xbcb/ui-types';
import { AppRecordKebabMenuItem } from '../../types';
import { WorkOrderTask } from '@xbcb/api-gateway-client';
import {
  AccountType,
  WorkOrderTaskCustomFieldType,
  AnyObject,
} from '@xbcb/shared-types';
import { WorkOrderTaskType } from '@xbcb/work-order-types';
import { isDeliveryOrderAutomationEnabled } from '../../../libs/featureFlags/deliveryOrderAutomationFeatureFlags';
import { isExamOrHoldWorkOrderTask } from './isExamOrHoldWorkOrderTask';
import getQueryParameters from '../../../libs/getQueryParameters';
import { Location } from 'history';

const checkTerminalCode = (
  tasks: WorkOrderTask[],
  workOrderTaskType: WorkOrderTaskType,
): boolean => {
  const task = tasks.find(
    (task: WorkOrderTask) =>
      task.definition?.workOrderTaskType === workOrderTaskType,
  );
  return Boolean(
    task?.customFields?.some(
      (field) =>
        field.customFieldType === WorkOrderTaskCustomFieldType.Text &&
        field.label === 'Terminal' &&
        field.textValue,
    ),
  );
};

const validateGenerateExamDeliveryOrderRequest = (
  record: AnyObject,
  location: Location,
): AdditionalFormError[] => {
  const validationErrors: AdditionalFormError[] = [];

  if (!record.exam?.site) {
    validationErrors.push({
      title: 'Missing exam site code in Exam/Hold Tab',
      messages: ['Exam site code is required'],
    });
  }
  const queryParameters = getQueryParameters(location);
  const workOrderTaskType = queryParameters['task'];

  if (
    !checkTerminalCode(record.tasks, workOrderTaskType as WorkOrderTaskType)
  ) {
    validationErrors.push({
      title: 'Missing Terminal code in Custom Fields Tab',
      messages: ['Terminal code is required'],
    });
  }
  return validationErrors;
};

export const appRecordGenerateExamDeliveryOrderMenuItem: AppRecordKebabMenuItem =
  {
    text: 'Generate Exam Delivery Order',
    key: KebabMenuItemKey.GENERATE_EXAM_DELIVERY_ORDER,
    action: async ({ record, location }) => {
      const errors = validateGenerateExamDeliveryOrderRequest(record, location);
      if (errors.length) {
        showValidationErrors(errors);
        return;
      }
    },
    show: ({ currentUser, workOrderTaskType }) =>
      currentUser.accountType === AccountType.OPERATOR &&
      isExamOrHoldWorkOrderTask(workOrderTaskType) &&
      isDeliveryOrderAutomationEnabled,
  };
