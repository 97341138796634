import { VneRegulatoryCategory } from '__generated__/graphql';
import { RemarksCode, RemarksCodeOption, toRemarksCodeOption } from '../types';

export const lightDutyEp2ImportCodes: RemarksCode[] = [
  {
    code: 'B',
    description: 'U.S.-certified',
  },
  {
    code: 'F',
    description: 'U.S.-certified catalyst restoration',
  },
  {
    code: 'EE',
    description:
      'Identical in all material respects to a U.S.-certified version',
  },
  {
    code: 'FF',
    description: 'Canadian "identical" models imported for resale or lease',
  },
  {
    code: 'M',
    description: 'Miscellaneous exemption',
  },
  {
    code: 'E',
    description: 'Vehicle at least 21 years old',
  },
  {
    code: 'L',
    description: 'Racing vehicle',
  },
  {
    code: 'U',
    description:
      '2005 model year (or older) motorcycle, scooter or moped with engine displacement less than 50cc and with rated speed greater',
  },
  {
    code: 'W',
    description: 'Non-chassis-mounted engine',
  },
  {
    code: 'Y',
    description: 'Unregulated fuel',
  },
  {
    code: 'G',
    description: 'Imported for repair or alteration',
  },
  {
    code: 'I',
    description: 'Imported for testing purposes',
  },
  {
    code: 'K',
    description: 'Imported for display',
  },
  {
    code: 'N',
    description:
      'Imported for up to one year by a member of the armed forces or personnel of a foreign government on assignment to the U.S',
  },
  {
    code: 'O',
    description:
      'Imported by non-resident for personal use by an individual for a period up to a year',
  },
  {
    code: 'A',
    description:
      'Imported by an ICI for modifications in accordance with a valid EPA certificate of conformity',
  },
  {
    code: 'C',
    description:
      'Imported by an ICI for modification and testing in accordance with 40 CFR 85.1509 (Vehicle over 6 years old)',
  },
  {
    code: 'J',
    description:
      'Imported by an ICI for the purpose of pre-certification testing to obtain an EPA certificate of conformity',
  },
  {
    code: 'Z',
    description:
      'Imported by an ICI for purpose of modifying to be identical to an original equipment manufacturer certified version',
  },
  {
    code: 'H',
    description:
      'Imported, owned, and controlled directly by an original equipment manufacturer (OEM), research, development, or testing purposes',
  },
  {
    code: 'Q',
    description:
      'Imported, owned, and controlled directly by an original equipment manufacturer (OEM), for storage pending receipt of applicable EPA certificate of conformity, which is pending and imminent. Use of this code is no longer permitted once EPA has issued the applicable certificate of conformity.',
  },
];

export const heavyDutyImportCodes = [
  {
    code: '1',
    description:
      'U.S.-certified engine or engine installed in a certified vehicle',
  },
  { code: '2', description: 'National Security' },
  { code: '3', description: 'Manufacturer-owned engine' },
  { code: '4', description: 'Replacement engine' },
  { code: '5', description: 'Extraordinary circumstances/hardship' },
  { code: '6', description: 'Hardship for small volume manufacturers' },
  { code: '7', description: 'Equipment-manufacturer hardship' },
  { code: '8', description: 'Identical configuration' },
  { code: '10', description: 'Repairs or alterations' },
  { code: '11', description: 'Testing' },
  { code: '12', description: 'Display' },
  { code: '13', description: 'Export' },
  { code: '14', description: 'Diplomatic or military' },
  { code: '15', description: 'Delegated assembly' },
  { code: '16', description: 'Partially complete engine' },
  {
    code: '17',
    description: 'Engine manufactured before emission standards started',
  },
  { code: 'to', description: 'apply' },
  { code: '18', description: 'Competition engine' },
  {
    code: '19',
    description:
      'Stationary compression-ignition engine with displacement at or above 30 liters per cylinder or stationary spark-ignition engine above 19kW that is not designed to run on gasoline or, if rich-burn, on liquefied petroleum gas.',
  },
  { code: '20', description: 'Underground mining' },
  { code: '21', description: 'Hobby engine' },
  { code: '22', description: 'Transition program for equipment manufacturers' },
  {
    code: '23',
    description: 'Personal-use exemption for small spark-ignition engines',
  },
  {
    code: '24a',
    description:
      'Engine imported by an independent commercial importer recognized by EPA',
  },
  {
    code: '24b',
    description:
      'Engine imported by an independent commercial importer recognized by EPA',
  },
  {
    code: '24c',
    description:
      'Engine imported by an independent commercial importer recognized by EPA',
  },
  { code: '25', description: 'Other exemption' },
];

const IMPORT_CODES_BY_REGULATORY_CATEGORY = {
  [VneRegulatoryCategory.LIGHT_DUTY_ON_ROAD]: lightDutyEp2ImportCodes,
  [VneRegulatoryCategory.HEAVY_DUTY_HIGHWAY_AND_NON_ROAD]: heavyDutyImportCodes,
};

export const importCodeOptions = (
  regulatoryCategory: VneRegulatoryCategory,
): RemarksCodeOption[] => {
  const codes = IMPORT_CODES_BY_REGULATORY_CATEGORY[regulatoryCategory];
  return codes.map(toRemarksCodeOption);
};

// Code requirements defined in CATAIR Supplemental Guidelines
// https://www.cbp.gov/sites/default/files/assets/documents/2024-Feb/ACE%20CATAIR%20EPA%20Supplemental%20Guidelines%20v16%2011-30-23%20(508)%20(1)_0.pdf#page=30
