import { RcFile } from 'antd/lib/upload';
import { Tooltip, message, Button, Upload } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import { mutateRecord, client as apolloClient } from '@xbcb/apollo-client';
import {
  DocumentFileExtension,
  DocumentTag,
  isfTemplateId,
} from '@xbcb/document-types';
import { documentFragments } from '@xbcb/document-queries';
import { ObjectType, RecordType } from '@xbcb/shared-types';
import { useMutation } from '@apollo/client';
import {
  createOneMutation,
  createSearchQueryVariables,
  getOneQuery,
  getRecordFromResponse,
  SearchQuery,
} from '@xbcb/shared-queries';
import {
  fileMetadata,
  openDocumentInNewTab,
  reportError,
  uploadDocuments,
  getEnv,
} from '@xbcb/ui-utils';
import { WorkOrderTab, UiStage } from '@xbcb/ui-types';
import { AppRecordSecondaryHeaderContentProps } from 'routes';
import { useQueryParams } from 'libs/hooks';
import { StyledDiv, StyledButton } from './styles';

const UsIsfSecondaryRightHeader: React.FC<
  AppRecordSecondaryHeaderContentProps
> = ({ record }) => {
  const [activeTab] = useQueryParams('tab');
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(false);
  const [isDownLoadButtonDisabled, setIsDownloadButtonDisabled] =
    useState(false);
  const [createDocument] = useMutation(
    createOneMutation({
      recordName: ObjectType.DOCUMENT,
      fields: '...documentFields',
      fragments: documentFragments,
    }),
  );

  const { stage } = getEnv();
  const getQuery = getOneQuery({
    recordName: RecordType.DOCUMENT,
    fields: '...documentFields',
    fragments: documentFragments,
  });
  const searchQuery = SearchQuery({
    recordName: RecordType.DOCUMENT,
    fields: '...documentFields',
    fragments: documentFragments,
  });

  // Use Beta template for local
  const isfTemplateDocumentId =
    stage !== UiStage.LOCAL
      ? isfTemplateId[stage.toLowerCase() as keyof typeof isfTemplateId]
      : isfTemplateId[UiStage.BETA.toLowerCase() as keyof typeof isfTemplateId];

  const handleDownload = async () => {
    setIsDownloadButtonDisabled(true);
    message.info('Downloading ISF template', 5.0);
    const response = await apolloClient.query({
      query: getQuery,
      variables: { id: isfTemplateDocumentId },
    });
    const document = getRecordFromResponse(
      response,
      'get',
      RecordType.DOCUMENT,
    );
    const { downloadLink } = document?.content || {};

    if (downloadLink) {
      openDocumentInNewTab(downloadLink);
      message.success('download complete');
    } else {
      message.error(
        'Sorry, we were unable to retrieve the ISF template. Please try again later.',
        5.0,
      );
    }
    setIsDownloadButtonDisabled(false);
  };

  const searchQueryVariables = createSearchQueryVariables({
    deletedTimeExists: false,
    operatorId: record.operator.id,
    tags: [],
    sortOptions: [
      {
        field: 'createdTime',
      },
    ],
  });

  const handleUpload = async (file: RcFile) => {
    setIsUploadButtonDisabled(true);
    try {
      // TODO consolidate with @xbcb/document-components/UploadDocBox/createDocuments
      // asana task https://app.asana.com/0/1200492611594632/1200512955320782
      if (file.size > 20000000) {
        message.error(
          `${file.name} is too large, each document must be less than 20MB.`,
          5.0,
        );
        return;
      }
      message.info(`Uploading ISF template ${file.name}`);
      const document = fileMetadata(file);
      const tagsForNewDocuments = [
        { key: 'shipmentId', value: record.group.shipment.id },
        {
          key: 'workOrderGroupId',
          value: record.group.id,
        },
        {
          key: 'usIsfId',
          value: record.id,
        },
      ];
      const optimisticTags = tagsForNewDocuments.map((tag) => ({
        ...tag,
        __typename: 'Tag',
      }));
      const response = await mutateRecord({
        mutationType: 'create',
        mutation: createDocument,
        mutationVariables: {
          idempotencyKey: uuidv4(),
          input: {
            ...document,
            tags: tagsForNewDocuments,
            documentTags: [DocumentTag.ISF_WORKSHEET],
            operator: {
              id: record.operator.id,
            },
            extension: file.name.split('.')[1].toUpperCase(),
          },
        },
        overwriteOptimisticRecordFields: { tags: optimisticTags },
        optimisticUpdateParams: {
          gqlQueryString: searchQuery,
          updatedQueryType: 'search',
          variables: searchQueryVariables,
        },
        recordType: RecordType.DOCUMENT,
      });
      await uploadDocuments([file], [response]);

      message.success('Upload success');
      message.info(
        `We're processing your spreadsheet, this may take a few minutes.`,
        3.0,
      );
    } catch (e) {
      reportError(e);
      message.error(
        'Sorry, an error has occurred. Please try again later.',
        5.0,
      );
    }
    setIsUploadButtonDisabled(false);
  };
  return activeTab === WorkOrderTab.ISF ? (
    <StyledDiv>
      <Tooltip
        title={
          <span>Upload our completed template to map the ISF fields below</span>
        }
        placement="bottom"
      >
        <StyledButton disabled={isUploadButtonDisabled} shape="round">
          <Upload
            beforeUpload={(file) => {
              handleUpload(file);
              return false;
            }}
            accept={`.${DocumentFileExtension.XLS}, .${DocumentFileExtension.XLSX}`}
            multiple={false}
            showUploadList={false}
            disabled={false}
          >
            <UploadOutlined />
            <span> Upload ISF Template</span>
          </Upload>
        </StyledButton>
      </Tooltip>

      <Tooltip
        placement="bottom"
        title={<span>Download our ISF Template</span>}
      >
        <Button
          key="export"
          onClick={handleDownload}
          shape="circle"
          disabled={isDownLoadButtonDisabled}
        >
          <DownloadOutlined />
        </Button>
      </Tooltip>
    </StyledDiv>
  ) : null;
};

export default UsIsfSecondaryRightHeader;
