import { isRealHts as realHTS } from '@xbcb/entry-utils/dist/lib/htsUtil';
import { AnyObject } from '@xbcb/shared-types';
import type { AdditionalFormError, NamePath } from '@xbcb/ui-types';
import { ValidationErrorType } from '@xbcb/ui-types';
import {
  defaultCreatePartyValidatorEmbeddedFields,
  getCodes,
  validateFormSnapshotFields,
} from '@xbcb/ui-utils';
import { formatHTS } from 'libs/htsHelpers';
import { roundNumber } from 'libs/format';
import { usCbpEntryFeeCodeToTypeMap } from '../taxesFeesBimaps';
import { hasLicenseNumberRequirement } from './productLineValidationHelpers';

const CHINA_COUNTRY_CODE = 'CN';
const COUNTRY_REQUIRING_STATE_CODE = 'CA';
const DEFAULT_PRODUCT_VALUE = 0;
const DEFAULT_NET_WEIGHT = 0;

const CHAPTER_98_REQUIRING_FORMAL_ENTRY = ['98110060'];
const hasHTSRequiringFormalEntry = (htsNumber?: string) => {
  if (!htsNumber) return false;
  return (
    htsNumber.startsWith('99') ||
    CHAPTER_98_REQUIRING_FORMAL_ENTRY.includes(htsNumber)
  );
};

const hasInvalidUnitAssist = ({ value }: AnyObject): boolean =>
  value === undefined || value <= 0;

export interface ProductValidateResult {
  errors: AdditionalFormError[];
  validateFields: NamePath[];
  adCvdEntry: boolean;
  formalEntryRequiredByHTS: boolean;
  netWeight: number;
  productValue: number;
}

export const validateProduct = (
  product: any = {},
  productIndex: number,
  invoiceIndex: number,
): ProductValidateResult => {
  const codes = getCodes();
  let adCvdEntry = false;
  let formalEntryRequiredByHTS = false;
  let netWeight = DEFAULT_NET_WEIGHT;
  const errors: AdditionalFormError[] = [];
  const validateFields: NamePath[] = [];
  const productNamePath = ['invoices', invoiceIndex, 'products', productIndex];
  validateFields.push([...productNamePath, 'quantity']);

  const productQuantity = product.quantity;
  if (productQuantity === 0) {
    errors.push({
      title: 'Product Quantity',
      type: ValidationErrorType.GENERAL,
      path: [...productNamePath, 'quantity'],
      messages: [`Quantity must be at least 1`],
    });
  }

  const totalValueNamePath = [...productNamePath, 'totalValue'];
  validateFields.push([...totalValueNamePath, 'value']);
  validateFields.push([...totalValueNamePath, 'currency']);

  const totalAssistNamePath = [...productNamePath, 'totalAssist'];

  const productTotalValue = product.totalValue?.value;
  const roundedProductTotalValue = roundNumber(
    productTotalValue || DEFAULT_PRODUCT_VALUE,
    2,
  ) as number;

  let linesValue = 0;
  product?.lines?.forEach((line: any, lineIndex: number) => {
    const { adCase, cvCase, origin, taxOptions, feeOptions, tariffs } = line;
    if (adCase?.id || cvCase?.id) {
      adCvdEntry = true;
    }
    const lineDisplayIndex = lineIndex + 1;
    const lineNamePath = [...productNamePath, 'lines', lineIndex];
    validateFields.push([...lineNamePath, 'description']);
    validateFields.push([...lineNamePath, 'origin', 'countryCode']);
    if (origin?.countryCode === COUNTRY_REQUIRING_STATE_CODE) {
      validateFields.push([...lineNamePath, 'origin', 'stateCode']);
    }

    const isManufacturerFromChina =
      line.manufacturer?.address?.countryCode === CHINA_COUNTRY_CODE;
    const manufacturerValidationFields = [
      ...defaultCreatePartyValidatorEmbeddedFields,
      ['mid'],
    ];

    if (product?.product?.id) {
      // If a product is selected we disable the COO and Manufacturer
      // fields and rely on the product compliance details. In this
      // case we need to validate manually since the user can't update
      // these values.

      if (!origin?.countryCode) {
        errors.push({
          title: 'Missing Origin Country Code',
          type: ValidationErrorType.GENERAL,
          path: lineNamePath,
          messages: [
            `Line ${lineDisplayIndex}: product is missing country of origin`,
          ],
        });
      }
      if (!line?.manufacturer) {
        errors.push({
          title: 'Missing Manufacturer',
          type: ValidationErrorType.GENERAL,
          path: lineNamePath,
          messages: [
            `Line ${lineDisplayIndex}: product is missing manufacturer`,
          ],
        });
      }
    }

    if (isManufacturerFromChina) {
      manufacturerValidationFields.push(['address', 'postalCode']);
    }
    const manufacturerPath = ['manufacturer', 'supplier', 'id'];
    const snapshotErrors = validateFormSnapshotFields({
      input: line,
      idPath: manufacturerPath,
      displayName: `Line ${lineDisplayIndex}: manufacturer`,
      embeddedFields: manufacturerValidationFields,
    });
    errors.push(
      ...snapshotErrors.map((error) => ({
        ...error,
        path: lineNamePath,
        type: ValidationErrorType.SNAPSHOT,
      })),
    );
    validateFields.push(manufacturerPath);
    validateFields.push([...lineNamePath, 'tariffs', 0, 'htsNumber']);

    if (hasLicenseNumberRequirement(line.licenseCode)) {
      validateFields.push([...lineNamePath, 'licenseNumber']);
    }

    taxOptions?.forEach(
      (
        { disclaim, type }: { disclaim?: boolean; type?: string },
        taxOptionIndex: number,
      ) => {
        if (disclaim) return;

        validateFields.push([
          ...lineNamePath,
          'taxOptions',
          taxOptionIndex,
          'type',
        ]);

        if (!type) return;
        // We passed the fee.class to get the taxOptionType. Now we
        // need to retrieve the fee.class back
        const feeClass = usCbpEntryFeeCodeToTypeMap.reverseGet(type);
        // We should also check !disclaim, but we already return early
        // if disclaim is true
        if (codes.CBP.CATAIR.taxClass[feeClass]) {
          validateFields.push([
            ...lineNamePath,
            'taxOptions',
            taxOptionIndex,
            'rate',
          ]);
        }
      },
    );

    feeOptions?.forEach((feeOption: any, feeOptionIndex: number) => {
      validateFields.push([
        ...lineNamePath,
        'feeOptions',
        feeOptionIndex,
        'type',
      ]);
    });

    // There must always be at least one tariff
    if (!tariffs || tariffs.length === 0) {
      errors.push({
        title: 'Tariffs',
        type: ValidationErrorType.GENERAL,
        path: [...lineNamePath, 'tariffs'],
        messages: [
          `Line ${lineDisplayIndex}: at least one tariff must be provided`,
        ],
      });
    }

    tariffs?.forEach((tariff: any = {}, tariffIndex: number) => {
      const tariffNamePath = [...lineNamePath, 'tariffs', tariffIndex];
      // validateFields.push([...tariffNamePath, 'unitValue', 'value']);
      // validateFields.push([...tariffNamePath, 'unitValue', 'currency']);
      validateFields.push([...tariffNamePath, 'htsNumber']);

      const htsNumber = tariff.htsNumber
        ? formatHTS(tariff.htsNumber)
        : undefined;
      if (hasHTSRequiringFormalEntry(htsNumber)) {
        formalEntryRequiredByHTS = true;
      }

      const validateTariff = tariffIndex === 0 || htsNumber;
      const tariffUnitValueValue = tariff.unitValue?.value;
      const htsHasUnitValue =
        tariff.unitValue?.value || (htsNumber && realHTS(htsNumber));
      if (
        validateTariff &&
        productQuantity &&
        tariffUnitValueValue &&
        htsHasUnitValue
      ) {
        const amount = productQuantity * tariffUnitValueValue;
        if (!isNaN(amount)) linesValue += amount;
      }

      if (tariff.unitAssist) {
        if (hasInvalidUnitAssist(tariff.unitAssist)) {
          const tariffDisplayIndex = tariffIndex + 1;
          errors.push({
            title: 'Tariff Unit Assist',
            type: ValidationErrorType.GENERAL,
            path: [...tariffNamePath, 'unitAssist'],
            messages: [
              `Line ${lineDisplayIndex}, tariff ${tariffDisplayIndex} has a negative number or zero for its unit assist`,
            ],
          });
        } else {
          validateFields.push([...totalAssistNamePath, 'value']);
          validateFields.push([...totalAssistNamePath, 'currency']);
        }
      }

      if (htsNumber && realHTS(htsNumber)) {
        tariff.unitReportingQuantities?.forEach(
          (quantity: any, quantityIndex: number) => {
            validateFields.push([
              ...tariffNamePath,
              'unitReportingQuantities',
              quantityIndex,
              'value',
            ]);
            validateFields.push([
              ...tariffNamePath,
              'unitReportingQuantities',
              quantityIndex,
              'unit',
            ]);

            const value = quantity.value;
            if (
              validateTariff &&
              quantity.unit === 'KG' &&
              value &&
              productQuantity
            ) {
              netWeight += productQuantity * value;
            }
          },
        );
      }
    });
  });

  if (
    productTotalValue &&
    roundedProductTotalValue !== roundNumber(linesValue, 2)
  ) {
    errors.push({
      title: 'Product Value Error',
      type: ValidationErrorType.GENERAL,
      path: [...productNamePath, 'totalValue'],
      messages: [
        `Product ${
          productIndex + 1
        }: Total product value does not match the sum of unit value * quantity (${roundNumber(
          linesValue,
          2,
        )})`,
      ],
    });
  }

  return {
    errors,
    validateFields,
    adCvdEntry,
    formalEntryRequiredByHTS,
    netWeight,
    productValue: roundedProductTotalValue,
  };
};
