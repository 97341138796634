import type { UsPgaFlag } from '__generated__/graphql';
import { ModeOfTransport } from '@xbcb/shared-types';
import type { NamePath, AdditionalFormError } from '@xbcb/ui-types';
import {
  createAccumulator,
  pgaHasProcessingCodes,
  subjectToAphCore,
  validateAph,
  validateFda,
  validateNht,
  validateTtb,
} from '.';
import { registeredEpaValidator } from './pga';
import { PgaWithDisclaim } from './pgaWithDisclaim';

export const validatePga = (
  tariffNamePath: NamePath,
  pgaFlag: UsPgaFlag,
  agencyCode: string,
  pga: PgaWithDisclaim,
  validatePartySnapshot: (
    idPath: NamePath,
    displayName: string,
    embeddedFields?: NamePath[],
  ) => void,
  manufacturerCountryOfOrigin?: string,
  mot?: ModeOfTransport,
): { validateFields: NamePath[]; additionalErrors: AdditionalFormError[] } => {
  const { disclaim, deviceInitialImporter } = pga;
  const programCode = pga.programCode ?? undefined;

  const getPgaValidationField = (fieldName: string | (string | number)[]) => {
    if (typeof fieldName === 'string') {
      return [...tariffNamePath, 'pga', pgaFlag, fieldName];
    } else {
      return [...tariffNamePath, 'pga', pgaFlag, ...fieldName];
    }
  };

  const additionalErrors: AdditionalFormError[] = [];
  const validateFields: NamePath[] = [];
  if (!pga?.agencyCode) return { additionalErrors, validateFields };

  const accumulator = createAccumulator({ additionalErrors, validateFields });

  if (!agencyCode) {
    return { additionalErrors, validateFields };
  }

  if (!disclaim || agencyCode !== 'FDA') {
    validateFields.push(getPgaValidationField('programCode'));
  }

  if (subjectToAphCore(pgaFlag) && !disclaim) {
    validateFields.push(getPgaValidationField('intendedUse'));
  }

  if (disclaim) {
    validateFields.push(getPgaValidationField('disclaimerCode'));
    return { additionalErrors, validateFields };
  }

  // If `pga?.programCode === 'DEV'`, then:
  //    1. If `dii` is `undefined`, we should validate `deviceInitialImporter`
  //       since `sameAsIor` or a `deviceInitialImporter` must be selected
  //       and currently neither are selected (this is most commonly happening
  //       when an IOR has a non-US `countryCode` because then we don't show the
  //       `sameAsIor` toggle and thus `dii` is `undefined` by default rather
  //       than defaulting to `sameAsIor = true` like we do when an IOR has a US
  //       `countryCode`). This is effectively the same as #2 below because
  //       if `dii` is `undefined`, `sameAsIor` is considered `false` and so
  //       `deviceInitialImporter` should show as required
  //    2. If `!dii.sameAsIor`, then `deviceInitialImporter` must
  //       be selected (so we should go into the conditional and validate)
  if (programCode === 'DEV' && !deviceInitialImporter?.sameAsIor) {
    validatePartySnapshot(
      [
        ...tariffNamePath,
        'pga',
        pgaFlag,
        'deviceInitialImporter',
        'customsAgent',
        'id',
      ],
      'Device Initial Importer',
    );
  }

  const hasProcessingCodes = pgaHasProcessingCodes(agencyCode, programCode);
  if (hasProcessingCodes) {
    validateFields.push(getPgaValidationField('processingCode'));
  }

  if (agencyCode === 'FDA') {
    accumulator([
      validateFda({
        tariffNamePath,
        pgaFlag,
        pga,
        getField: getPgaValidationField,
        manufacturerCountryOfOrigin,
        mot,
      }),
    ]);
  }
  if (agencyCode === 'TTB') {
    accumulator([validateTtb({ pga, getField: getPgaValidationField })]);
  }
  if (agencyCode === 'APH') {
    accumulator([
      validateAph({
        tariffNamePath,
        pga,
        pgaFlag,
        getField: getPgaValidationField,
      }),
    ]);
  }
  if (agencyCode === 'NHT') {
    accumulator([validateNht({ pga, getField: getPgaValidationField })]);
  }

  if (agencyCode === 'EPA') {
    const epaFieldsToValidate = registeredEpaValidator.validate({
      tariffNamePath,
      pga,
      pgaFlag,
    });
    const validationResult = {
      validateFields:
        epaFieldsToValidate?.validateFields?.map((it) =>
          getPgaValidationField(it),
        ) ?? [],
    };
    accumulator([validationResult]);
  }

  return {
    validateFields,
    additionalErrors,
  };
};
