import cleanDeep from 'clean-deep';
import { last, pick } from 'lodash';
import {
  CreateUsType86EntryInput,
  ExternalComplianceReference,
  Product,
  ShipmentBadge,
  ShipmentBuyer,
  ShipmentComplianceDetail,
  ShipmentLeg,
  ShipmentSeller,
  ShipmentShipTo,
  UsConsignee,
  UsIor,
  UsType86Entry,
  UsType86EntryArrivalInput,
  UsType86EntryCommercialInvoiceInput,
  UsType86EntryConsigneeInput,
  UsType86EntryConveyanceInput,
  UsType86EntryDepartureInput,
  UsType86EntryIorInput,
  UsType86EntryLineInput,
  UsType86EntryProductInput,
  UsType86EntryTariffInput,
} from '@xbcb/api-gateway-client';
import log from '@xbcb/log';
import {
  ISOCurrencyCode,
  ModeOfTransport,
  RecordType,
} from '@xbcb/shared-types';
import {
  getPortOfLadingFromPortCode,
  getPortOfUnladingFromPortCode,
} from './getPort';
import { ComplianceRefToProductMap, createShipmentTags } from './shared';
import { sortObjectKeys } from '@xbcb/js-utils';

type TransformShipmentLegToUsType86EntryInputProps = {
  badges?: ShipmentBadge[];
  shipmentLeg: ShipmentLeg;
  clientIdentifier?: string;
  wogId: string;
  shipmentId: string;
  operatorId: string;
  buyer?: ShipmentBuyer;
  sellers?: ShipmentSeller[];
  shipTo?: ShipmentShipTo;
  complianceRefToProductMap: ComplianceRefToProductMap;
  existingWorkOrder?: UsType86Entry;
};

export const transformShipmentLegToUsType86EntryInput = ({
  shipmentLeg,
  clientIdentifier,
  wogId,
  shipmentId,
  operatorId,
  sellers,
  shipTo,
  complianceRefToProductMap,
  existingWorkOrder,
}: TransformShipmentLegToUsType86EntryInputProps) => {
  const {
    modeOfTransport,
    arrival,
    containers,
    masterBills,
    ior,
    consignee,
    departure,
    conveyance,
    commercialInvoices,
    loadType,
  } = shipmentLeg;
  let input = {
    tags: createShipmentTags({ shipmentId, clientIdentifier }),
    group: { id: wogId },
    operator: { id: operatorId },
    conveyance: { modeOfTransport },
    broker: {
      usCustomsBroker: {
        id: arrival?.customsBroker?.customsBroker?.id,
      },
    },
    containers,
    masterBills,
    loadType,
  } as CreateUsType86EntryInput;
  log.info(`Processed UsType86EntryInput for shipment ${shipmentId}`, {
    key: 'transformShipmentLegToUsType86EntryInput',
  });

  // For Blackjack use case container size is 1
  if (containers && masterBills) {
    let totalQuantity = 0;
    for (let i = 0; i < containers.length; i++) {
      const currentQuantity = containers[i].quantity;
      totalQuantity = currentQuantity
        ? currentQuantity + totalQuantity
        : totalQuantity;
    }

    // Type86 will have only 1 master bill and 1 house bill
    if (masterBills.length === 1) {
      const firstMasterBill = masterBills[0];
      const houseBills = firstMasterBill.houseBills;
      if (!houseBills || houseBills.length === 1) {
        const number = houseBills ? houseBills[0].number : undefined;
        input.masterBills = [
          {
            number: firstMasterBill.number,
            houseBills: [{ quantity: totalQuantity, number }],
          },
        ];
      }
    }
  }
  log.info(`Processed UsType86Entry Bills for shipment ${shipmentId}`, {
    key: 'transformShipmentLegToUsType86EntryInput',
  });

  const shipmentIor = ior?.ior;
  if (shipmentIor) {
    const { id, version, name, iorNumber, addresses, pointOfContact } =
      shipmentIor as UsIor;
    const usType86EntryIor = {
      usIor: { id, version },
      name,
      iorNumber: { type: iorNumber?.type, value: iorNumber?.value },
      address: addresses?.mailing || addresses?.physical,
      pointOfContact: pointOfContact
        ? pick(pointOfContact, ['name', 'email', 'phone'])
        : undefined,
    } as UsType86EntryIorInput;
    input = { ...input, ior: usType86EntryIor };
  }
  log.info(`Processed UsType86Entry Ior for shipment ${shipmentId}`, {
    key: 'transformShipmentLegToUsType86EntryInput',
  });

  const shipmentConsignee = consignee?.consignee;
  if (shipmentConsignee) {
    const { id, version, name, iorNumber, addresses, pointOfContact } =
      shipmentConsignee as UsConsignee;
    const usType86EntryConsignee = {
      usConsignee: { id, version },
      name,
      iorNumber: { type: iorNumber?.type, value: iorNumber?.value },
      address: addresses?.mailing || addresses?.physical,
      pointOfContact: pointOfContact
        ? pick(pointOfContact, ['name', 'email', 'phone'])
        : undefined,
      sameAsIor: existingWorkOrder?.consignee?.sameAsIor, // Populate from existing entry data
    } as UsType86EntryConsigneeInput;
    input = { ...input, consignee: usType86EntryConsignee };
  }
  log.info(`Processed UsType86Entry consignee for shipment ${shipmentId}`, {
    key: 'transformShipmentLegToUsType86EntryInput',
  });

  let usType86EntryDeparture = {
    exportCountryCode: departure?.country,
    exportDate: departure?.time,
  } as UsType86EntryDepartureInput;
  log.info(`Processed UsType86Entry Departure for shipment ${shipmentId}`, {
    key: 'transformShipmentLegToUsType86EntryInput',
  });

  const shipmentPortOfLading = departure?.portOfLading;
  if (shipmentPortOfLading) {
    const portOfLadingCode = getPortOfLadingFromPortCode(shipmentPortOfLading);

    if (portOfLadingCode) {
      usType86EntryDeparture = {
        ...usType86EntryDeparture,
        portOfLadingCode: portOfLadingCode as string,
      };
    } else {
      log.error(
        `Entry doesn't consume portCode because shipment has portOfLading with type [${shipmentPortOfLading.type}] and value [${shipmentPortOfLading.value}]`,
        {
          string: RecordType.SHIPMENT,
          id: shipmentId,
          key: 'PortCodeMappingMissing',
        },
      );
    }
  }

  let usType86EntryArrival = {
    usDestinationStateCode: shipTo?.address?.stateCode,
    importDate: arrival?.time,
    firmsCode: arrival?.firmsCode,
  } as UsType86EntryArrivalInput;
  log.info(`Processed UsType86Entry Arrival for shipment ${shipmentId}`, {
    key: 'transformShipmentLegToUsType86EntryInput',
  });

  const shipmentPortOfUnlading = arrival?.portOfUnlading;
  if (shipmentPortOfUnlading) {
    const portOfUnladingCode = getPortOfUnladingFromPortCode(
      shipmentPortOfUnlading,
    );
    if (portOfUnladingCode) {
      usType86EntryArrival = {
        ...usType86EntryArrival,
        portOfUnladingCode: portOfUnladingCode as string,
      };
    } else {
      log.error(
        `Entry doesn't consume portCode because shipment has portOfUnLading with type [${shipmentPortOfUnlading.type}] and value [${shipmentPortOfUnlading.value}]`,
        {
          string: RecordType.SHIPMENT,
          id: shipmentId,
          key: 'PortCodeMappingMissing',
        },
      );
    }
  }

  const { containerized, conveyanceName, tripNumber, grossWeight } =
    conveyance || {};
  const usType86EntryConveyance = {
    modeOfTransport,
    containerized,
    conveyanceName,
    tripNumber,
    grossWeight,
  } as UsType86EntryConveyanceInput;
  log.info(`Processed UsType86Entry Conveyance for shipment ${shipmentId}`, {
    key: 'transformShipmentLegToUsType86EntryInput',
  });

  input = {
    ...input,
    departure: usType86EntryDeparture,
    arrival: usType86EntryArrival,
    conveyance: usType86EntryConveyance,
  };

  if (sellers && sellers.length > 1) {
    throw new Error(
      `Multiple sellers ${sellers.length} on shipment level not supported.`,
    );
  }
  const commercialInvoiceSeller = sellers?.[0];
  const { name, address, seller } = commercialInvoiceSeller || {};
  let totalValueAmount;
  if (commercialInvoices && commercialInvoices.length) {
    const usType86EntryInvoices = commercialInvoices?.map(
      ({ invoiceNumber, complianceDetails }) => {
        const products = complianceDetails?.map((complianceDetail) =>
          getEntryProductLines(complianceDetail, complianceRefToProductMap),
        );
        totalValueAmount = products?.reduce((sum, product) => {
          const lineValue = product?.totalValue?.value;
          return sum + (lineValue || 0);
        }, 0);
        return {
          invoiceNumber,
          products,
          ...(commercialInvoiceSeller && {
            seller: {
              name,
              address,
              supplier: { id: seller?.id, version: seller?.version },
              mid: (seller as any)?.mid,
            },
          }),
          ...(totalValueAmount && {
            value: {
              currency:
                products?.[0]?.totalValue?.currency || ISOCurrencyCode.USD,
              value: totalValueAmount,
            },
          }),
        } as UsType86EntryCommercialInvoiceInput;
      },
    );
    input = {
      ...input,
      invoices: usType86EntryInvoices,
      ...(totalValueAmount ? { totalValue: totalValueAmount } : {}),
    };
    log.info(
      `Processed UsType86Entry CommercialInvoices for shipment ${shipmentId}`,
      {
        key: 'transformShipmentLegToUsType86EntryInput',
      },
    );
  }

  if (modeOfTransport === ModeOfTransport.OCEAN) {
    input.conveyance.containerized = true;
  }

  // entryTypeCode for Type86
  input.entryType = '86';

  return cleanDeep(input, {
    emptyStrings: false,
  }) as CreateUsType86EntryInput;
};

const getEntryProductLines = (
  complianceDetail: ShipmentComplianceDetail,
  complianceRefToProductMap: ComplianceRefToProductMap,
) => {
  const { complianceDetailsReference, quantity } = complianceDetail;
  const product =
    complianceRefToProductMap[
      JSON.stringify(sortObjectKeys(complianceDetailsReference))
    ];
  if (!product) return undefined;
  const productLines = constructEntryProductLines(
    complianceDetailsReference,
    product,
  );
  // Currencies for unit price across different lines.
  const currencies = new Set();
  const linesValue = productLines.reduce((sum, line) => {
    const unitValue = last(
      line.tariffs as UsType86EntryTariffInput[],
    )?.unitValue;
    if (unitValue) currencies.add(unitValue?.currency);
    return sum + (unitValue?.value || 0);
  }, 0);

  if (linesValue && currencies.size !== 1) {
    throw new Error(
      `Multiple currencies ${currencies.size} not supported across different lines.`,
    );
  }

  const { id, version } = product;
  return {
    product: { id, version },
    quantity: quantity,
    ...(quantity &&
      Boolean(linesValue) && {
        totalValue: {
          currency: currencies.values().next().value,
          value: linesValue * quantity,
        },
      }),
    lines: productLines,
  } as UsType86EntryProductInput;
};

const constructEntryProductLines = (
  complianceDetailsReference: ExternalComplianceReference,
  product: Product,
) =>
  product.complianceDetails?.us?.entryLines?.map((productLine) => {
    const supplier = productLine.manufacturer;
    const { addresses, pointOfContact, complianceDetails, name } =
      supplier || {};
    return {
      ...productLine,
      ...(supplier && {
        manufacturer: {
          address: addresses?.mailing || addresses?.physical,
          pointOfContact:
            pointOfContact && pick(pointOfContact, ['name', 'email', 'phone']),
          mid: complianceDetails?.us?.mid,
          name,
          supplier: pick(supplier, ['id', 'version']),
        },
      }),
      externalReference: complianceDetailsReference,
    } as UsType86EntryLineInput;
  }) || [];
