import cleanDeep from 'clean-deep';
import {
  UsIsfIorInput,
  UsIsfConsigneeInput,
  UsIsfManufacturerInput,
  UsIsfSellerInput,
  UsIsfStuffingLocationInput,
  UsIsfConsolidatorInput,
  UsIsfShipToInput,
  UsIsfBuyerInput,
  CreateUsIsfInput,
  ShipmentLeg,
  ShipmentBuyer,
  ShipmentSeller,
  ShipmentManufacturer,
  ShipmentShipTo,
  ShipmentStuffingLocation,
  ShipmentConsolidator,
  UsIor,
  UsConsignee,
  Supplier,
  UsIsfProductInput,
} from '@xbcb/api-gateway-client';
import { isEmpty, last } from 'lodash';
import log from '@xbcb/log';
import { RecordType } from '@xbcb/shared-types';
import { UsIsfShipmentTypeCode, IsfType } from '@xbcb/work-order-types';
import { ModeOfTransport } from '@xbcb/shipment-types';
import { getPortOfUnladingFromPortCode } from './getPort';
import { ComplianceRefToProductMap, createShipmentTags } from './shared';

type TransformShipmentLegToUsIsfInputProps = {
  shipmentLeg: ShipmentLeg;
  clientIdentifier?: string;
  wogId: string;
  shipmentId: string;
  operatorId: string;
  buyer?: ShipmentBuyer;
  sellers?: ShipmentSeller[];
  manufacturers?: ShipmentManufacturer[];
  shipTo?: ShipmentShipTo;
  stuffingLocation?: ShipmentStuffingLocation;
  consolidator?: ShipmentConsolidator;
  complianceRefToProductMap: ComplianceRefToProductMap;
};

export const transformShipmentLegToUsIsfInput = ({
  shipmentLeg,
  clientIdentifier,
  wogId,
  shipmentId,
  operatorId,
  buyer,
  sellers,
  manufacturers,
  shipTo,
  stuffingLocation,
  consolidator,
  complianceRefToProductMap,
}: TransformShipmentLegToUsIsfInputProps) => {
  const { arrival, masterBills, ior, consignee, loadType, modeOfTransport } =
    shipmentLeg;
  const { customsBroker, time: arrivalTime, portOfUnlading } = arrival || {};
  let input = {
    tags: createShipmentTags({ shipmentId, clientIdentifier }),
    group: { id: wogId },
    operator: { id: operatorId },
    broker: {
      usCustomsBroker: {
        id: customsBroker?.customsBroker?.id,
      },
    },
    masterBills: masterBills,
    arrivalTime,
    placeOfDelivery: shipTo?.name,
    loadType,
    isfType:
      modeOfTransport === ModeOfTransport.RAIL ? IsfType.ISF_5 : IsfType.ISF_10,
  } as CreateUsIsfInput;

  const shipmentIor = ior?.ior;
  if (shipmentIor) {
    // Need `as UsIor` because TS knows iorNumber does not exist on `Ior` (the
    // interface) but we expect this to be a UsIor and the iorNumber defined
    const { id, version, name, iorNumber, personalEffects } =
      shipmentIor as UsIor;
    const usIsfIor = {
      usIor: { id: id, version: version },
      name,
      iorNumber: { type: iorNumber?.type, value: iorNumber?.value },
    } as UsIsfIorInput;
    input = { ...input, ior: usIsfIor };
    // Personal effects
    if (personalEffects) input.shipmentTypeCode = UsIsfShipmentTypeCode.HHG_PE;
  }

  if (portOfUnlading) {
    const portOfUnladingCode = getPortOfUnladingFromPortCode(portOfUnlading);
    if (portOfUnladingCode) {
      input = { ...input, portOfUnladingCode: portOfUnladingCode as string };
    } else {
      log.error(
        `UsIsf doesn't consume portCode because shipment has portOfUnLading with type [${portOfUnlading.type}] and value [${portOfUnlading.value}]`,
        {
          string: RecordType.SHIPMENT,
          id: shipmentId,
          key: 'PortCodeMappingMissing',
        },
      );
    }
  }

  const shipmentConsignee = consignee?.consignee;
  if (shipmentConsignee) {
    const { id, version, iorNumber } = shipmentConsignee as UsConsignee;
    const { type, value } = iorNumber || {};
    const usIsfConsignee = {
      usConsignee: { id, version },
      iorNumber: { type, value },
    } as UsIsfConsigneeInput;
    input = { ...input, consignee: usIsfConsignee };
  }

  // Construct isfManfs from compliance details if present, else fall back to manfs on shipment level.
  const usIsfManufacturersFromComplianceDetails =
    constructManfsFromComplianceDetails(shipmentId, complianceRefToProductMap);
  const usIsfManufacturers = !isEmpty(usIsfManufacturersFromComplianceDetails)
    ? usIsfManufacturersFromComplianceDetails
    : manufacturers?.map(({ name, address, duns, manufacturer: supplier }) => {
        return {
          name,
          address,
          duns,
          supplier: {
            id: supplier?.id,
            version: supplier?.version,
          },
        } as UsIsfManufacturerInput;
      });

  const usIsfSellers: UsIsfSellerInput[] = [];
  sellers?.forEach(({ name, address, duns, ein, seller }) => {
    const sellerInput = {
      name,
      address,
      duns,
      ein,
      seller: {
        id: seller?.id,
        version: seller?.version,
      },
    } as UsIsfSellerInput;
    usIsfSellers.push(sellerInput);
  });

  const usIsfStuffingLocation = {
    name: stuffingLocation?.name,
    address: stuffingLocation?.address,
    duns: stuffingLocation?.duns,
    stuffingLocation: {
      id: stuffingLocation?.stuffingLocation?.id,
      version: stuffingLocation?.stuffingLocation?.version,
    },
  } as UsIsfStuffingLocationInput;

  const usIsfConsolidator = {
    name: consolidator?.name,
    address: consolidator?.address,
    duns: consolidator?.duns,
    consolidator: {
      id: consolidator?.consolidator?.id,
      version: consolidator?.consolidator?.version,
    },
  } as UsIsfConsolidatorInput;

  const usIsfShipTo = {
    name: shipTo?.name,
    address: shipTo?.address,
    duns: shipTo?.duns,
    shipTo: {
      id: shipTo?.shipTo?.id,
      version: shipTo?.shipTo?.version,
    },
  } as UsIsfShipToInput;

  const usIsfBuyer = {
    name: buyer?.name,
    address: buyer?.address,
    duns: buyer?.duns,
    ein: buyer?.ein,
    buyer: {
      id: buyer?.buyer?.id,
      version: buyer?.buyer?.version,
    },
  } as UsIsfBuyerInput;

  input = {
    ...input,
    manufacturers: usIsfManufacturers,
    sellers: usIsfSellers,
    stuffingLocation: usIsfStuffingLocation,
    consolidator: usIsfConsolidator,
    shipTo: usIsfShipTo,
    buyer: usIsfBuyer,
  };

  const sanitizedInput = cleanDeep(input, {
    emptyStrings: false,
  }) as CreateUsIsfInput;
  return sanitizedInput;
};

// Creates ISF manufacturers from AFT compliance details if present.
const constructManfsFromComplianceDetails = (
  shipmentId: string,
  complianceRefToProductMap?: ComplianceRefToProductMap,
) => {
  if (isEmpty(complianceRefToProductMap)) return;
  const supplierIdToProductDetailsMap: {
    [key: string]: UsIsfProductInput[];
  } = {};
  const supplierIdToSupplierDetailsMap: { [key: string]: Supplier } = {};

  // Query each product to construct supplier to product map.
  Object.values(complianceRefToProductMap).forEach(
    ({ id, version, complianceDetails }) => {
      // Assumes there would be only one product line from aft
      const firstEntryLine = complianceDetails?.us?.entryLines?.[0];
      if (firstEntryLine) {
        // NOTE: The manufacturer here is not the original manufacturer, it's
        // the queried manufacturer with all fields provided
        const { manufacturer, tariffs, origin } = firstEntryLine;
        const htsNumber = last(tariffs)?.htsNumber;
        const originCountryCode = origin?.countryCode;
        if (manufacturer && originCountryCode) {
          const supplierId = manufacturer.id;
          if (!supplierIdToProductDetailsMap[supplierId]) {
            supplierIdToProductDetailsMap[supplierId] = [];
          }
          supplierIdToProductDetailsMap[supplierId].push({
            htsNumber,
            originCountryCode,
            product: { id, version },
          });
          supplierIdToSupplierDetailsMap[supplierId] = manufacturer;
        }
      }
    },
  );

  log.info(`Constructed supplierIdToProductDetailsMap`, {
    params: supplierIdToProductDetailsMap,
    id: shipmentId,
    key: 'supplierIdToProductDetailsMap',
  });

  const usIsfManufacturerInputs = Object.keys(
    supplierIdToProductDetailsMap,
  ).map((supplierId) => {
    // NOTE: The manufacturer here is not the original manufacturer, it's
    // the queried manufacturer with all fields provided
    const { addresses, name, id, version } =
      supplierIdToSupplierDetailsMap[supplierId];
    return {
      address: addresses?.mailing || addresses?.physical,
      name,
      supplier: { id, version },
      products: supplierIdToProductDetailsMap[supplierId],
    } as UsIsfManufacturerInput;
  });
  return usIsfManufacturerInputs;
};
