import { recordActionFields, recordFields } from '@xbcb/shared-queries';

export const commodityGroupFields = `
fragment commodityGroupFields on CommodityGroup {
  ...recordFields
  name
  description
  htsChapters
  pgas
}
`;

export const commodityGroupFragments = `
${commodityGroupFields}
${recordFields}
${recordActionFields}
`;
