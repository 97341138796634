import React from 'react';
import { VehiclesAndEnginesFormProps } from '../../types';
import { useTheme } from 'styled-components';
import { FormItem, Select } from '@xbcb/form-item-components';
import { VneRegulatoryCategory } from '__generated__/graphql';
import { Form } from 'antd';

export const RegulatoryCategory: React.FC<VehiclesAndEnginesFormProps> = ({
  localNamePath,
  fullNamePath,
  disabled,
  readOnly,
  required,
}) => {
  const theme = useTheme();

  const form = Form.useFormInstance();

  const clearField = (fieldPath: string[]) =>
    form.setFieldValue([...fullNamePath, ...fieldPath], undefined);

  const clearConditionalFields = () => {
    clearField(['vneImportCode']);
    clearField(['vneIndustryCode']);
  };

  return (
    <FormItem
      $itemSize={theme.size.medium}
      label="Regulatory Category"
      name={[...localNamePath, 'regulatoryCategory']}
      $readOnly={readOnly}
      rules={[{ required }]}
    >
      <Select
        aria-label={'Regulatory Category Select'}
        disabled={disabled}
        options={regulatoryCategories}
        onChange={clearConditionalFields}
      />
    </FormItem>
  );
};

export const regulatoryCategories = [
  {
    label: 'Light Duty On Road Vehicles and Engines',
    value: VneRegulatoryCategory.LIGHT_DUTY_ON_ROAD,
  },
  {
    label: 'Heavy Duty Highway Engines and Non Road Engines',
    value: VneRegulatoryCategory.HEAVY_DUTY_HIGHWAY_AND_NON_ROAD,
  },
];
