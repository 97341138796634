import React, { memo } from 'react';
import { Checkbox, CountrySelect } from '@xbcb/form-item-components';
import { NamePath } from '@xbcb/ui-types';
import { Form } from 'antd';

export interface SteelMeltAndPourCountryDetailProps {
  localNamePath: NamePath;
  fullNamePath: NamePath;
}

export const steelCountryOfMeltAndPourLabel = 'Country of Melt and Pour';
export const derivativeSteelProductOfMexicoOnlyLabel =
  'Derivative Product of Mexico Only';

export enum Section232SteelRequirementsFields {
  STEEL_COUNTRY_OF_MELT_AND_POUR = 'steelCountryOfMeltAndPour',
  DERIVATIVE_STEEL_PRODUCT_OF_MEXICO_ONLY = 'derivativeSteelProductOfMexicoOnly',
}

export const steelRequirementsFieldPrefix = 'steelRequirements';

export const SteelMeltAndPourCountryDetail: React.FC<
  SteelMeltAndPourCountryDetailProps
> = ({ localNamePath, fullNamePath }) => {
  const fieldLocalNamePath = (field: Section232SteelRequirementsFields) => {
    return [...localNamePath, steelRequirementsFieldPrefix, field];
  };

  const fieldFullNamePath = (fieldName: Section232SteelRequirementsFields) => {
    return [...fullNamePath, steelRequirementsFieldPrefix, fieldName];
  };

  const form = Form.useFormInstance();

  const derivativeSteelProductOfMexicoOnly = Form.useWatch(
    fieldFullNamePath(
      Section232SteelRequirementsFields.DERIVATIVE_STEEL_PRODUCT_OF_MEXICO_ONLY,
    ),
  );

  return (
    <>
      <Checkbox
        localNamePath={fieldLocalNamePath(
          Section232SteelRequirementsFields.DERIVATIVE_STEEL_PRODUCT_OF_MEXICO_ONLY,
        )}
        fullNamePath={fieldFullNamePath(
          Section232SteelRequirementsFields.DERIVATIVE_STEEL_PRODUCT_OF_MEXICO_ONLY,
        )}
        form={form}
        text={derivativeSteelProductOfMexicoOnlyLabel}
      />
      <CountrySelect
        label={steelCountryOfMeltAndPourLabel}
        localNamePath={fieldLocalNamePath(
          Section232SteelRequirementsFields.STEEL_COUNTRY_OF_MELT_AND_POUR,
        )}
        required={!derivativeSteelProductOfMexicoOnly}
      />
    </>
  );
};

export default memo(SteelMeltAndPourCountryDetail);
