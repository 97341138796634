import { GLITCHWATCH_FEATURE, GlitchWatchFeatures } from '@xbcb/feature-flags';
import { AccountType } from '@xbcb/shared-types';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import { CurrentUser } from 'libs/hooks';

const { stage: envStage } = getEnv();
const stage = uiStageToBackendStage[envStage];

export const isGlitchWatchUiAdminViewEnabled = (user: CurrentUser): boolean => {
  if (user.accountType !== AccountType.OPERATOR) return false;
  return TechTeam.isMember(user);
};

class TechTeam {
  private static GAMMA_TEAM_IDS = [
    'businessSupportTeam_06ad7389-85d0-45c6-914f-01c82c2d969d',
    'businessSupportTeam_88336167-d9af-47a3-8de6-6d3415a48a18',
    'businessSupportTeam_3a4c9291-215c-4b63-8a73-44352f20f81d',
  ];

  private static PROD_TEAM_IDS = [
    'businessSupportTeam_30c0058a-e5d1-4609-a3f9-05115b6d1b24',
    'businessSupportTeam_a39dc3ac-9196-4ffd-b0a3-92657f475b4f',
    'businessSupportTeam_ca5a888c-367f-43f1-98f3-d0a42686aeb5',
  ];

  private static TEAM_IDS = new Set([
    ...TechTeam.GAMMA_TEAM_IDS,
    ...TechTeam.PROD_TEAM_IDS,
  ]);

  static isMember({ businessSupportTeams }: CurrentUser): boolean {
    for (const node of businessSupportTeams?.nodes ?? []) {
      if (TechTeam.TEAM_IDS.has(node.id)) {
        return true;
      }
    }
    return false;
  }
}

export const isGlitchWatchUiAlertButtonEnabled = GLITCHWATCH_FEATURE.isEnabled(
  GlitchWatchFeatures.UI_ALERT_BUTTON,
  {
    stage,
  },
);
