import { recordActionFields, recordFields } from '@xbcb/shared-queries';

export const operatorUserPermissionFields = `
permissions {
  usIorActivation {
    name
  }
  document {
    name
  }
  documentTemplate {
    name
  }
  customsAgent {
    name
  }
  facility {
    name
  }
  forwarder {
    name
  }
  deCustomsEntry {
    name
  }
  gbCustomsEntry {
    name
  }
  nlCustomsEntry {
    name
  }
  frCustomsEntry {
    name
  }
  usType86Entry {
    name
  }
  shipper {
    name
  }
  supplier {
    name
  }
  trucker {
    name
  }
  usConsignee {
    name
  }
  usIor {
    name
  }
  gbIor {
    name
  }
  deIor {
    name
  }
  nlIor {
    name
  }
  frIor {
    name
  }
  usConsumptionEntry {
    name
  }
  usPostSummaryCorrection {
    name
  }
  usIsf {
    name
  }
  invoice {
    name
  }
  invoiceLinesRequest {
    name
  }
  payableInvoice {
    name
  }
  product {
    name
  }
  shipment {
    name
  }
  workOrderTask {
    name
  }
  operatorUser {
    name
  }
  reportReconciliationRequest {
    name
  }
  usIorContinuousBondRequest {
    name
  }
  usInBond {
    name
  }
  usCustomsBroker {
    name
  }
  company {
    name
  }
  usDrawbackClaim {
    name
  }
  usIorActivation {
    name
  }
  deliveryOrder {
    name
  }
  gbIorActivation {
    name
  }
  deIorActivation {
    name
  }
  nlIorActivation {
    name
  }
  frIorActivation {
    name
  }
  gbConsignee {
    name
  }
  ukCustomsBroker {
    name
  }
  deConsignee {
    name
  }
  nlConsignee {
    name
  }
  nlCustomsBroker {
    name
  }
  frConsignee {
    name
  }
  frCustomsBroker {
    name
  }
  company {
    name
  }
  deCustomsBroker {
    name
  }
  company {
    name
  }
  assignmentTeam {
    name
  }
  businessSupportTeam {
    name
  }
  commodityGroup {
    name
  }
  memo { 
    name 
  }
  bulkMilestoneUploadRequest {
    name
  }
  bulkChargesUploadRequest {
    name
  }
  bulkComplianceUploadRequest {
    name
  }
  exchangeMessage {
    name
  }
  workOrderTaskEscalationMessage {
    name
  }
  broker {
    name
  }
  customsBroker {
   name
  }
  customsDeclaration {
    name
  }
  importer {
    name
  }
  globalConsignee {
    name
  }
  importerEntity {
    name
  }
  importerEntityActivation {
    name
  }
}
`;

export const userNotificationFields = `
notifications {
  type
  deliveryMethods
}
`;
export const operatorUserPermissions = `
operatorUserPermissions: ${operatorUserPermissionFields}
`;

export const shipperUserPermissionFields = `
permissions {
  document {
    name
  }
  documentTemplate {
    name
  }
  customsAgent {
    name
  }
  facility {
    name
  }
  forwarder {
    name
  }
  supplier {
    name
  }
  trucker {
    name
  }
  usConsignee {
    name
  }
  usIor {
    name
  }
  usConsumptionEntry {
    name
  }
  usIsf {
    name
  }
  invoice {
    name
  }
  product {
    name
  }
  shipment {
    name
  }
  shipperUser {
    name
  }
  usInBond {
    name
  }
  usCustomsBroker {
    name
  }
  usIorContinuousBondRequest {
    name
  }
  company {
    name
  }
  usIorActivation {
    name
  }
  usPostSummaryCorrection {
    name
  }
  usDrawbackClaim {
    name
  }
  deliveryOrder {
    name
  }
  bulkMilestoneUploadRequest {
    name
  }
  bulkChargesUploadRequest {
    name
  }
  bulkComplianceUploadRequest {
    name
  }
}
`;
export const shipperUserPermissions = `
shipperUserPermissions: ${shipperUserPermissionFields}
`;

export const forwarderUserPermissionFields = `
permissions {
  document {
    name
  }
  documentTemplate {
    name
  }
  customsAgent {
    name
  }
  facility {
    name
  }
  shipper {
    name
  }
  supplier {
    name
  }
  trucker {
    name
  }
  usConsignee {
    name
  }
  usIor {
    name
  }
  usConsumptionEntry {
    name
  }
  usIsf {
    name
  }
  invoice {
    name
  }
  product {
    name
  }
  shipment {
    name
  }
  forwarderUser {
    name
  }
  usInBond {
    name
  }
  usCustomsBroker {
    name
  }
  usIorContinuousBondRequest {
    name
  }
  usIorActivation {
    name
  }
  usPostSummaryCorrection {
    name
  }
  usDrawbackClaim {
    name
  }
  company {
    name
  }
  deliveryOrder {
    name
  }
  bulkMilestoneUploadRequest {
    name
  }
  bulkChargesUploadRequest {
    name
  }
  bulkComplianceUploadRequest {
    name
  }
}
`;

export const forwarderUserPermissions = `
forwarderUserPermissions: ${forwarderUserPermissionFields}
`;

export const userFragment = `
fragment userFields on User {
  name
  title
  email
  root
  appLastVersionSeen
  loginLink
  limitNotifications
  mobilePhone {
    country
    number
    extension
  }
  officePhone {
    country
    number
    extension
  }
  cognito {
    sub
    id
  }
  avatarUrl
  away
  teams {
    id
    name
    tags { 
      key 
      value 
    }
  }
  ... on ForwarderUser {
    operator {
      id
    }
  }
  ... on OperatorUser {
    operator {
      id
    }
  }
  ... on ShipperUser {
    operator {
      id
    }
  }
}
`;

export const userFields = userFragment; // TODO deprecate

export const userWorkDayFields = `
fragment userWorkDayFields on UserWorkDay {
  start
  end
}
`;

export const userWorkingHoursFields = `
fragment userWorkingHoursFields on UserWorkingHours {
  sunday {
    ...userWorkDayFields
  }
  monday {
    ...userWorkDayFields
  }
  tuesday {
    ...userWorkDayFields
  }
  wednesday {
    ...userWorkDayFields
  }
  thursday {
    ...userWorkDayFields
  }
  friday {
    ...userWorkDayFields
  }
  saturday {
    ...userWorkDayFields
  }
}
`;

export const userFragments = `
${userFields}
${recordFields}
${recordActionFields}
`;
