import { gql } from '@apollo/client';
import { recordFields, recordActionFields } from '@xbcb/shared-queries';

export const queryExchangeMessage = gql`
  query ExchangeMessage($id: ID!) {
    exchangeMessage(id: $id) {
      ...recordFields
      id
      version
      sender
      receiver
      type
      status
      inboxMetadata {
        from
        to
        subject
        body
        workOrderId
      }
      output {
        downloadLink
      }
      tags {
        key
        value
      }

      documents {
        id
        version
        fileName
        extension
        content {
          downloadLink
        }
        tags {
          key
          value
        }
      }
    }
  }
  ${recordFields}
  ${recordActionFields}
`;
